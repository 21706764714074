import {
	AccountBillToDialogFormContainer,
	AccountBillToDialogTitle,
	AllFieldsText,
	HeaderTitleStyles,
	StartOverContinueContainer
} from "./NewOrders.styles";
import { Content } from "pages/Replacements/components/AddNewAddressModal/addNewAddressModal.styles.ts";
import { AllFieldsRequiredText, ContinueButtonText, SelectAccountAndBillingText, StartOverText } from "constants/text";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import AccountBillToContent from "./AccountBillToContent";
import { ImportConfigurationViewModel } from "data/api/v1";
import { FileContents } from "features/reducers/newOrder/newOrder.ts";
import { useCheckForBillToAccountErrors } from "./utils/NewOrderUtils";

interface AccountBillToDialogFormProps {
	handleFindOriginalOrder: () => void;
	handleModalContinue: () => void;
}

const AccountBillToDialogForm = ({ handleFindOriginalOrder, handleModalContinue }: AccountBillToDialogFormProps) => {
	const csvContents: FileContents | undefined = useSelector((state: RootState) => state.newOrder.parsedCSV);

	return (
		<AccountBillToDialogFormContainer>
			<HeaderTitleStyles>
				<AccountBillToDialogTitle>{SelectAccountAndBillingText}</AccountBillToDialogTitle>
				<AllFieldsText className="body2">{AllFieldsRequiredText}</AllFieldsText>
			</HeaderTitleStyles>
			<Content>
				{csvContents?.configurations?.map((configuration: ImportConfigurationViewModel, index) => (
					<AccountBillToContent
						key={`${configuration.doorStyleCode}: ${configuration.doorStyle} + ${configuration.finishCode}: ${configuration.finish}`}
						csvContents={csvContents}
						index={index}
					/>
				))}

				<StartOverContinueContainer>
					<Button
						onClick={handleFindOriginalOrder}
						variant="outlined"
						fullWidth
					>
						{StartOverText}
					</Button>

					<Button
						onClick={useCheckForBillToAccountErrors(handleModalContinue)}
						variant="contained"
						fullWidth
					>
						{ContinueButtonText}
					</Button>
				</StartOverContinueContainer>
			</Content>
		</AccountBillToDialogFormContainer>
	);
};

export default AccountBillToDialogForm;
