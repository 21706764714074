import {
	NewOrderBuildGridDoorStyleColumnHeader,
	NewOrderBuildGridFinishColumnHeader,
	NewOrderBuildGridQuantityColumnHeader,
	NewOrderViewPriceEstimateEstimatedSubtotalHeader,
	NewOrderViewPriceEstimateExtendedPriceColumnHeader,
	NewOrderViewPriceEstimateListPriceColumnHeader
} from "components/NewOrders/constants";
import { brandCheck } from "utils/order";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import {
	BuildNewOrderLineItemsHeader,
	BuildNewOrderLineItemsHeaderWrapper,
	SummaryProductLine
} from "../NewOrders.styles";
import {
	AccountBillToCard,
	AccountBillToCardContainer,
	AccountBillToCardDetails,
	AccountBillToCardDetailsContainer,
	AccountBillToCardHeader,
	EstimatesLineItemGrid,
	PriceEstimatesAccordion,
	TotalPriceContainer
} from "./viewPriceEstimates.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LineItemAccordionDetails } from "../../Common/LineItemGrid/LineItemGridStyles";
import Header from "components/Common/LineItemGrid/Header/Header";
import Modification from "../../Common/LineItemGrid/Modification/Modification";
import { ViewPriceEstimatesConfigurationModel } from "./view-price-estimates-model";
import { Collapse } from "@mui/material";
import BillToBox from "components/Common/BillToShipToBox/BillToBox/BillToBox";
import { OrderType } from "data/api/v1";
import { useLocation } from "react-router-dom";

interface ViewPriceEstimatesLineItemsProps {
	priceEstimateConfiguration: ViewPriceEstimatesConfigurationModel;
	searchValue: string;
}

const ViewPriceEstimatesLineItems = ({ priceEstimateConfiguration, searchValue }: ViewPriceEstimatesLineItemsProps) => {
	const billTo = priceEstimateConfiguration?.billToNumber;
	const location = useLocation();
	return (
		<PriceEstimatesAccordion
			defaultExpanded
			slots={{
				transition: (props) => (
					<div>
						<Collapse {...props} />
						<TotalPriceContainer>
							<p>{NewOrderViewPriceEstimateEstimatedSubtotalHeader}</p>
							<p data-testid="view-price-estimate-line-item-accordionSubtotal">
								{priceEstimateConfiguration.pricing?.extendedPrice?.toLocaleString("en-US", {
									style: "currency",
									currency: "USD"
								})}
							</p>
						</TotalPriceContainer>
					</div>
				)
			}}
		>
			<BuildNewOrderLineItemsHeaderWrapper expandIcon={<ExpandMoreIcon />}>
				<BuildNewOrderLineItemsHeader>
					<div data-testid="new-order-build-label">
						<div>
							{priceEstimateConfiguration?.productLine}, {priceEstimateConfiguration.doorStyle},{" "}
							{priceEstimateConfiguration.species}, {priceEstimateConfiguration.finish}
						</div>
						<div>
							{priceEstimateConfiguration?.shape}, {priceEstimateConfiguration.construction},{" "}
							{priceEstimateConfiguration?.packaging}
						</div>
					</div>
					<div data-testid="new-order-build-table-count">
						{priceEstimateConfiguration?.items.length}{" "}
						{`line item${priceEstimateConfiguration.items.length > 1 ? "s" : ""}`}
					</div>
				</BuildNewOrderLineItemsHeader>
			</BuildNewOrderLineItemsHeaderWrapper>
			<AccountBillToCardContainer>
				<AccountBillToCard>
					<AccountBillToCardHeader>Account #</AccountBillToCardHeader>
					<AccountBillToCardDetailsContainer>
						<AccountBillToCardDetails>
							<p data-testid="view-price-estimate-selected-account-number">
								{priceEstimateConfiguration.accountNumber?.accountNumber}
							</p>
							<p data-testid="view-price-estimate-selected-account-description">
								{priceEstimateConfiguration.accountNumber?.accountName}
							</p>
						</AccountBillToCardDetails>
						<div>
							<StyledSummaryCardLogo
								data-testid="view-price-estimate-account-card-brand-logo"
								parentBrand={priceEstimateConfiguration.parentBrand}
								src={
									brandCheck(priceEstimateConfiguration.parentBrand)
										? `/assets/manufacture_logos/${priceEstimateConfiguration.parentBrand}.png`
										: "/assets/tandem_logos/cwg_logo.png"
								}
								alt={
									brandCheck(priceEstimateConfiguration.parentBrand)
										? priceEstimateConfiguration.parentBrand
										: "CabinetworksGroup Logo"
								}
							/>
							{location.pathname == "/new-order/view-price-estimates" && (
								<SummaryProductLine>{priceEstimateConfiguration.productLine}</SummaryProductLine>
							)}
						</div>
					</AccountBillToCardDetailsContainer>
				</AccountBillToCard>
				<BillToBox
					billTo={billTo}
					orderType={OrderType.UNKNOWN}
					hasBorder
					containsSubtotal={false}
					dataTestIdPrefix="view-price-estimate-selected"
				/>
			</AccountBillToCardContainer>

			<LineItemAccordionDetails>
				{priceEstimateConfiguration?.items?.map((item) => {
					const foundSearch = `${item.userCode} - ${item.description}`
						.toUpperCase()
						.includes(searchValue.toUpperCase());
					if (foundSearch) {
						return (
							<EstimatesLineItemGrid
								key={`${item.userCode}-${item.lineNumber}`}
								mainRowContent={[
									<Header
										description={item.description ?? "--"}
										key={`${item.userCode}-${item.lineNumber}-header`}
										header={item.userCode ?? "--"}
										lineNumber={item.lineNumber}
									/>,
									<div key={`${item.userCode}-${item.lineNumber}-door-style`}>
										<div>{NewOrderBuildGridDoorStyleColumnHeader}</div>
										<div data-testid="view-price-estimate-line-item-doorStyle">
											{priceEstimateConfiguration.doorStyle}
										</div>
									</div>,
									<div key={`${item.userCode}-${item.lineNumber}-finish`}>
										<div>{NewOrderBuildGridFinishColumnHeader}</div>
										<div data-testid="view-price-estimate-line-item-finish">
											{priceEstimateConfiguration.finish}
										</div>
									</div>,
									<div key={`${item.userCode}-${item.lineNumber}-qty`}>
										<div>{NewOrderBuildGridQuantityColumnHeader}</div>
										<div data-testid="view-price-estimate-line-item-quantity">{item.quantity}</div>
									</div>,
									<div key={`${item.userCode}-${item.lineNumber}-list-price`}>
										<div>{NewOrderViewPriceEstimateListPriceColumnHeader}</div>
										<div data-testid="view-price-estimate-line-item-listPrice">
											{item?.listPrice?.toLocaleString("en-US", {
												style: "currency",
												currency: "USD"
											})}
										</div>
									</div>,
									<div key={`${item.userCode}-${item.lineNumber}-extended-price`}>
										<div>{NewOrderViewPriceEstimateExtendedPriceColumnHeader}</div>
										<div data-testid="view-price-estimate-line-item-extendedPrice">
											{item?.extendedPrice?.toLocaleString("en-US", {
												style: "currency",
												currency: "USD"
											})}
										</div>
									</div>
								]}
								modificationRows={item.modifications?.map((modification) => (
									<Modification
										description={modification.description ?? "--"}
										key={modification.userCode}
										extendedPrice={modification.extendedPrice}
										lineNumber={modification.lineNumber}
										listPrice={modification.listPrice}
										title={modification.userCode ?? "--"}
									/>
								))}
							/>
						);
					}
					return undefined;
				})}
			</LineItemAccordionDetails>
		</PriceEstimatesAccordion>
	);
};

export default ViewPriceEstimatesLineItems;
