import { CharacterLimitExceeded, RequiredFieldNotice } from "constants/text";
import { NewOrdersDialogWrapper } from "../NewOrders.styles";
import { IsLoadingBody, SaveDraftModalBody, SaveDraftModalButtons, SaveDraftModalHeader } from "./SaveDraft.styles";
import {
	ComeBackToOrderText,
	DraftNamePlaceholder,
	InputDraftTitleError,
	ReplacePreviouslySavedDraftText,
	SaveDraftHangTightText,
	SaveDraftSuccess,
	SaveDraftSuccessfullySavedText,
	SaveDraftText
} from "../constants";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Close from "@mui/icons-material/Close";
import type { DialogProps } from "@mui/material";
import { Button, LinearProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSaveDraftOrderMutation } from "features/api/orderApi.ts";
import { useDispatch } from "react-redux";
import HelperText from "components/Form/HelperText/HelperText";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";
import { v4 as uuidv4 } from "uuid";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model";
import { decodeBase64WhenEncoded } from "utils/string";

interface SaveDraftModalProps {
	saveDraftModalIsOpen: boolean;
	closeModal: () => void;
	handleSecondaryButton: () => void;
	firstButtonText: string;
	secondButtonText: string;
	draftOrder?: DraftOrderViewModel;
}

const SaveDraftModal = ({
	saveDraftModalIsOpen,
	closeModal,
	handleSecondaryButton,
	firstButtonText,
	secondButtonText,
	draftOrder
}: SaveDraftModalProps) => {
	const [draftTitle, setDraftTitle] = useState(decodeBase64WhenEncoded(draftOrder?.draftName ?? ""));
	const [errorText, setErrorText] = useState<undefined | string>(undefined);
	const [draftTitleHasError, setDraftTitleHasError] = useState(false);
	const [saveDraftOrder, { isLoading, isSuccess, reset, isError, error: errorData }] = useSaveDraftOrderMutation();
	const dispatch = useDispatch();
	const [isSavedDraft, setIsSavedDraft] = useState(draftOrder?.draftName && draftOrder.draftName !== "");
	const handleCloseModal = useCallback(() => {
		closeModal();
		setErrorText(undefined);
		setDraftTitleHasError(false);
		reset();
	}, [closeModal, reset]);

	useEffect(() => {
		if (isSuccess) {
			setTimeout(() => {
				dispatch(submittingNewOrderActions.updateDraftName(draftTitle));
				handleSecondaryButton();
				setTimeout(() => {
					reset();
				}, 300);
			}, 4000);
		} else if (isError) {
			handleCloseModal();
			dispatch(submittingNewOrderActions.updateDraftError(errorData));
		}
	}, [isSuccess, isError, dispatch, draftTitle, handleCloseModal, handleSecondaryButton, errorData, reset]);

	const submitDraft = async () => {
		const draftOrderId = draftOrder?.draftOrderId ? draftOrder.draftOrderId : uuidv4();
		const draft: DraftOrderViewModel = {
			...draftOrder,
			draftOrderId: draftOrderId,
			draftName: btoa(draftTitle),
			jobName: btoa(draftOrder?.jobName ?? ""),
			poNumber: btoa(draftOrder?.poNumber ?? ""),
			labelComments: btoa(draftOrder?.labelComments ?? ""),
			shipToAddress: {
				name: btoa(draftOrder?.shipToAddress?.name ?? ""),
				city: draftOrder?.shipToAddress?.city ?? "",
				line1: btoa(draftOrder?.shipToAddress?.line1 ?? ""),
				line2: btoa(draftOrder?.shipToAddress?.line2 ?? ""),
				line3: draftOrder?.shipToAddress?.line3 ?? "",
				phoneNumber: btoa(draftOrder?.shipToAddress?.phoneNumber ?? ""),
				email: btoa(draftOrder?.shipToAddress?.email ?? ""),
				mailStop: draftOrder?.shipToAddress?.mailStop ?? "",
				state: draftOrder?.shipToAddress?.state ?? "",
				zip: draftOrder?.shipToAddress?.zip ?? "",
				county: draftOrder?.shipToAddress?.county ?? ""
			}
		};
		try {
			await saveDraftOrder(draft);
			dispatch(submittingNewOrderActions.updateDraftOrder(draft));
			setIsSavedDraft(true);
			dispatch(submittingNewOrderActions.clearDraftError());
		} catch (error: any) {
			console.error("error:", error);
			handleCloseModal();
			dispatch(submittingNewOrderActions.updateDraftError(error));
		}
	};

	const checkForErrors = () => {
		if (draftTitle.trim() === "" && draftTitle.length <= DRAFT_TITLE_MAX_CHAR) {
			setDraftTitleHasError(true);
			setErrorText(InputDraftTitleError);
		}
		if (draftTitle.trim() !== "" && draftTitle.length <= DRAFT_TITLE_MAX_CHAR) {
			submitDraft();
		}
	};

	const DRAFT_TITLE_MAX_CHAR = 50;

	let content;
	if (isLoading) {
		content = (
			<IsLoadingBody>
				<h6>{SaveDraftHangTightText}</h6>
				<div>
					<span>{draftTitle}</span>
					<LinearProgress style={{ width: "100%" }} />
				</div>
			</IsLoadingBody>
		);
	} else if (isSuccess) {
		content = (
			<IsLoadingBody>
				<h6>{SaveDraftSuccessfullySavedText}</h6>
				<div>
					<span>{draftTitle}</span>
					<LinearProgress
						variant="determinate"
						style={{ width: "100%" }}
						value={100}
					/>
					<div>
						<CheckCircleOutline />
						<span>{SaveDraftSuccess}</span>
					</div>
				</div>
			</IsLoadingBody>
		);
	} else {
		content = (
			<>
				<p>{RequiredFieldNotice}</p>
				<TextField
					label={DraftNamePlaceholder}
					value={draftTitle}
					data-testid="save-draft-input"
					onChange={(e) => {
						setDraftTitleHasError(false);
						setDraftTitle(e.target.value);
					}}
					sx={{ marginBottom: "4px" }}
				/>
				<HelperText
					isError={draftTitle.length > DRAFT_TITLE_MAX_CHAR || draftTitleHasError}
					text={
						draftTitleHasError
							? errorText
							: draftTitle.length > DRAFT_TITLE_MAX_CHAR
								? CharacterLimitExceeded
								: ""
					}
					characterLimit={{
						currentLength: draftTitle.length,
						limit: DRAFT_TITLE_MAX_CHAR,
						isLimitError: draftTitle.length > DRAFT_TITLE_MAX_CHAR
					}}
				/>
				<SaveDraftModalButtons>
					<Button
						variant="outlined"
						onClick={handleCloseModal}
					>
						{secondButtonText}
					</Button>
					<Button
						variant="contained"
						onClick={checkForErrors}
					>
						{firstButtonText}
					</Button>
				</SaveDraftModalButtons>
			</>
		);
	}

	const handleDialogClose: DialogProps["onClose"] = (event, reason) => {
		if (reason && reason === "backdropClick" && (isSuccess || isLoading)) return;
		handleCloseModal();
	};

	return (
		<NewOrdersDialogWrapper
			open={saveDraftModalIsOpen}
			onClose={handleDialogClose}
		>
			<SaveDraftModalHeader>
				<div>
					<h6>{SaveDraftText}</h6>
					{!isLoading && !isSuccess && (
						<p>{isSavedDraft ? ReplacePreviouslySavedDraftText : ComeBackToOrderText}</p>
					)}
				</div>
				{!isLoading && !isSuccess && <Close onClick={handleCloseModal} />}
			</SaveDraftModalHeader>
			<SaveDraftModalBody isError={draftTitleHasError || draftTitle.length > DRAFT_TITLE_MAX_CHAR}>
				{content}
			</SaveDraftModalBody>
		</NewOrdersDialogWrapper>
	);
};

export default SaveDraftModal;
