import { useDispatch, useSelector } from "react-redux";
import { NewOrdersDialogWrapper } from "../NewOrders.styles";
import { RootState } from "stores/application.store";
import { NewOrderModalTracking } from "data/api/v1";
import AccountBillToDialogForm from "../AccountBillToDialogForm";
import { updateOrderDetails } from "pages/OrderPages/utils/OrderDetailUtil";
import { ImportOrderViewModel } from "data/api/v1/model/import-order-view-model";
import { useNavigate, useParams } from "react-router-dom";
import CSVUpload from "../CSVUpload";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder";

interface NewOrderDialog {
	isAddOn?: boolean;
}

const NewOrdersDialog = ({ isAddOn }: NewOrderDialog) => {
	const modalState = useSelector((state: RootState) => state.newOrder.modalStatus);
	const modalStateCSV = modalState === NewOrderModalTracking.CSV_UPLOAD;
	const modalStateAccount = modalState === NewOrderModalTracking.SELECT_BILLTO_ACCOUNT;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const handleCloseModal = () => {
		if (!modalStateAccount) {
			dispatch(newOrderActions.clearNewOrder());
		}
	};

	const handleStartOver = () => {
		dispatch(newOrderActions.clearNewOrder());
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
	};

	const parsedCSV: ImportOrderViewModel | undefined = useSelector((state: RootState) => state.newOrder.parsedCSV);

	const handleModalContinue = () => {
		const csvConfigurations = parsedCSV?.configurations?.map((config) => config ?? []);
		updateOrderDetails(csvConfigurations, dispatch, undefined, isAddOn, params.orderId);
		if (isAddOn) {
			navigate(`/add-ons/build-order/${params.orderId}`);
		} else {
			navigate("/new-order/build-order");
		}
	};

	return (
		<NewOrdersDialogWrapper
			open={modalStateCSV || modalStateAccount}
			onClose={handleCloseModal}
			disableEscapeKeyDown={modalStateAccount}
		>
			{modalStateAccount && (
				<AccountBillToDialogForm
					handleFindOriginalOrder={handleStartOver}
					handleModalContinue={handleModalContinue}
				/>
			)}

			{modalStateCSV && <CSVUpload handleClose={handleCloseModal} />}
		</NewOrdersDialogWrapper>
	);
};

export default NewOrdersDialog;
