import { ReviewOrderAccountBillToCardContainer } from "../Review/reviewOrder.styles";
import {
	AccountBillToCard,
	AccountBillToCardDetails,
	AccountBillToCardDetailsContainer,
	AccountBillToCardHeader
} from "../ViewPriceEstimates/viewPriceEstimates.styles";
import { NewOrderAccountNumber } from "../constants";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import { brandCheck } from "utils/order";
import { SummaryProductLine } from "../NewOrders.styles";
import BillToBox from "components/Common/BillToShipToBox/BillToBox/BillToBox";
import { ImportConfigurationViewModel, OrderType } from "data/api/v1";
import { NewOrdersForReview } from "pages/OrderPages/ReviewOrderPage/ReviewOrderPage";
import { useLocation } from "react-router-dom";

interface Props {
	newOrderConfig?: NewOrdersForReview;
	addOnConfig?: ImportConfigurationViewModel;
}

const ReviewOrderAccountBillTo = ({ newOrderConfig, addOnConfig }: Props) => {
	const config = newOrderConfig?.priceEstimateConfig ?? addOnConfig;
	const location = useLocation();
	return (
		<ReviewOrderAccountBillToCardContainer isAddOn={!!addOnConfig}>
			<AccountBillToCard>
				<AccountBillToCardHeader>{NewOrderAccountNumber}</AccountBillToCardHeader>
				<AccountBillToCardDetailsContainer>
					<AccountBillToCardDetails>
						<div data-testid="new-order-review-order-list-account-id">
							{config?.accountNumber?.accountNumber}
						</div>
						<div data-testid="new-order-review-order-list-account-name">
							{config?.accountNumber?.accountName}
						</div>
					</AccountBillToCardDetails>
					<div>
						<StyledSummaryCardLogo
							data-testid="new-order-review-order-list-brand-logo"
							parentBrand={config?.parentBrand}
							src={
								brandCheck(config?.parentBrand)
									? `/assets/manufacture_logos/${config?.parentBrand}.png`
									: "/assets/tandem_logos/cwg_logo.png"
							}
							alt={
								config?.parentBrand && brandCheck(config?.parentBrand)
									? config?.parentBrand
									: "CabinetworksGroup Logo"
							}
						/>
						{(location.pathname == "/new-order/review-order" ||
							location.pathname == "/new-order/confirmation") && (
							<SummaryProductLine>{config?.productLine}</SummaryProductLine>
						)}
					</div>
				</AccountBillToCardDetailsContainer>
			</AccountBillToCard>
			<BillToBox
				billTo={config?.billToNumber}
				orderType={OrderType.UNKNOWN}
				hasBorder
				containsSubtotal={false}
				dataTestIdPrefix="new-order-review-order-list"
			/>
		</ReviewOrderAccountBillToCardContainer>
	);
};

export default ReviewOrderAccountBillTo;
