import { Accordion, Button, Grid, TextField } from "@mui/material";
import { OrderDetailViewModel, ReplacementFormTracking } from "data/api/v1";
import styles from "pages/replacements-page-styles.module.css";
import {
	DesignerNumberText,
	EditText,
	JobNameOptionalText,
	OrderReplacementsPONumber,
	PONumberText,
	ReplacementReviewOrderDetailsHeader,
	ReplacementsCancelButtonText,
	RequiredFieldsText,
	SaveButtonText,
	UnavailableDataPlaceholderText
} from "constants/text";
import { RootState } from "stores/application.store";
import { useDispatch, useSelector } from "react-redux";
import {
	submittingReplacementOrderActions,
	OrderDetails
} from "features/reducers/replacementOrder/submittingReplacementOrder.ts";
import { displayDesigner } from "utils/order";
import { ReplacementReviewContentStyled } from "./ReplacementReview.styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	JOB_NAME_MAX_LENGTH,
	OrderDetailsFields,
	PO_NUMBER_MAX_LENGTH,
	schema
} from "components/Replacements/EditOrderDetailsForm/schema";
import { getFormFieldProps } from "utils/form";
import { Title } from "components/Replacements/components/common.styles";
import { LinkButton } from "components/Common/Link";

export interface ReviewEditOrderDetailsProp {
	orderDetail: OrderDetailViewModel | null;
}

const ReplacementReviewEditOrderDetails = ({ orderDetail }: ReviewEditOrderDetailsProp) => {
	const dispatch = useDispatch();
	const details: OrderDetails = useSelector((state: RootState) => state.submittingReplacementOrder.order);
	const { formState, handleSubmit, register, reset, watch } = useForm({
		mode: "onSubmit",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		defaultValues: {
			poNumber: details?.poNumber ?? orderDetail?.poNumber,
			jobName: details?.jobName ?? orderDetail?.jobName
		}
	});
	const formFieldData = { formState, register, schema };
	const poNumberInput = watch("poNumber") ?? "";
	const jobNameInput = watch("jobName") ?? "";

	const handleEditOrderDetails = () => {
		dispatch(submittingReplacementOrderActions.setEditState(ReplacementFormTracking.ORDER));
	};

	const handleOnCancel = () => {
		reset({
			poNumber: details?.poNumber ?? orderDetail?.poNumber,
			jobName: details?.jobName ?? orderDetail?.jobName
		});
		dispatch(submittingReplacementOrderActions.setEditState(ReplacementFormTracking.NONE));
	};

	const handleOnSave = (values: OrderDetailsFields) => {
		const editedOrderDetails = { jobName: values.jobName, poNumber: values.poNumber };
		dispatch(submittingReplacementOrderActions.updateOrderDetails(editedOrderDetails));
		dispatch(submittingReplacementOrderActions.setEditState(ReplacementFormTracking.NONE));
	};

	const editState = useSelector((state: RootState) => state.submittingReplacementOrder.editState);
	const editStateNone = editState === ReplacementFormTracking.NONE;

	return (
		<form
			onSubmit={(event) => {
				event.stopPropagation();
				handleSubmit(handleOnSave)(event);
			}}
			noValidate
		>
			<Grid
				container
				className={styles.orderDetailContainer}
			>
				<Grid
					container
					justifyContent="space-between"
				>
					<Grid item>
						<Title data-testid="replacement-review-order-details-header">
							{ReplacementReviewOrderDetailsHeader}
						</Title>
					</Grid>
					{editStateNone && (
						<Grid item>
							<div className={styles.editTextContainer}>
								<LinkButton
									data-testid="replacement-review-order-details-edit-link"
									onClick={handleEditOrderDetails}
									data-id="replacement-review-order-details-edit-link"
								>
									{EditText}
								</LinkButton>
							</div>
						</Grid>
					)}
				</Grid>

				<Grid
					container
					sx={{ marginTop: "1rem" }}
				>
					<ReplacementReviewContentStyled
						item
						xs={12}
						sm={6}
					>
						<div
							className="subtitle2"
							data-testid="replacement-review-order-details-designerNumber-header"
						>
							{DesignerNumberText}
						</div>
						<span
							className="body1"
							data-testid="replacement-review-order-details-designerNumber"
						>
							{displayDesigner(orderDetail?.designer) ?? UnavailableDataPlaceholderText}
						</span>
					</ReplacementReviewContentStyled>

					{(editStateNone || editState !== ReplacementFormTracking.ORDER) && (
						<ReplacementReviewContentStyled
							item
							xs={12}
							sm={6}
						>
							<div
								className="subtitle2"
								data-testid="replacement-review-order-details-poNumber-header"
							>
								{OrderReplacementsPONumber}
							</div>
							<span
								className="body2"
								data-testid="replacement-review-order-details-poNumber"
							>
								{details?.poNumber ?? orderDetail?.poNumber}
							</span>
						</ReplacementReviewContentStyled>
					)}

					{(editStateNone || editState !== ReplacementFormTracking.ORDER) && (
						<Grid
							item
							xs={12}
						>
							<div
								className="subtitle2"
								data-testid="replacement-review-order-details-jobName-header"
							>
								{JobNameOptionalText}
							</div>
							<span
								className="body2"
								data-testid="replacement-review-order-details-jobName"
							>
								{details?.jobName ?? orderDetail?.jobName}
							</span>
						</Grid>
					)}
				</Grid>

				{editState === ReplacementFormTracking.ORDER && (
					<Accordion
						elevation={0}
						defaultExpanded={Boolean(editState)}
						className={styles.accordionContainer}
					>
						<Grid
							container
							spacing={2}
							className={styles.accordionWrapper}
						>
							<Grid
								item
								xs={12}
								sm={6}
							>
								<span className="body2">{RequiredFieldsText}</span>
								<TextField
									{...getFormFieldProps({
										name: "poNumber",
										characterLimit: {
											currentLength: poNumberInput.length,
											limit: PO_NUMBER_MAX_LENGTH
										},
										...formFieldData
									})}
									fullWidth
									label={PONumberText}
									size="small"
									sx={{ marginTop: "1rem" }}
								/>
							</Grid>

							<Grid
								item
								xs={12}
								sm={6}
								sx={{ display: "flex", alignItems: "end" }}
							>
								<TextField
									{...getFormFieldProps({
										name: "jobName",
										characterLimit: {
											currentLength: jobNameInput.length,
											limit: JOB_NAME_MAX_LENGTH
										},
										...formFieldData
									})}
									fullWidth
									label={JobNameOptionalText}
									size="small"
								/>
							</Grid>
						</Grid>

						<Grid
							container
							spacing={2}
							sx={{ marginTop: ".5rem" }}
							className={styles.accordionWrapper}
						>
							<Grid
								item
								xs={6}
							>
								<Button
									fullWidth
									variant="outlined"
									sx={{ marginLeft: 0 }}
									data-testid="review-cancel-button"
									data-id="review-cancel-button"
									onClick={handleOnCancel}
								>
									{ReplacementsCancelButtonText}
								</Button>
							</Grid>

							<Grid
								item
								xs={6}
							>
								<Button
									type="submit"
									fullWidth
									sx={{ marginLeft: 0 }}
									data-testid="review-save-button"
									data-id="review-save-button"
									variant="contained"
								>
									{SaveButtonText}
								</Button>
							</Grid>
						</Grid>
					</Accordion>
				)}
			</Grid>
		</form>
	);
};

export default ReplacementReviewEditOrderDetails;
