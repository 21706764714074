import { useState } from "react";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import { SaveDraftButton } from "./SaveDraft.styles";
import { SaveDraftText } from "../constants";
import SaveDraftModal from "./SaveDraftModal";
import { CancelButtonText, SaveButtonText } from "constants/text";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model";

interface Props {
	draftOrder?: DraftOrderViewModel;
}

const SaveDraft = ({ draftOrder }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			<SaveDraftButton
				variant="text"
				onClick={handleOpen}
				data-testid="save-draft-button"
			>
				<SaveOutlined />
				{SaveDraftText}
			</SaveDraftButton>
			<SaveDraftModal
				draftOrder={draftOrder}
				closeModal={handleClose}
				handleSecondaryButton={handleClose}
				saveDraftModalIsOpen={isOpen}
				firstButtonText={SaveButtonText}
				secondButtonText={CancelButtonText}
			/>
		</>
	);
};

export default SaveDraft;
