import { object, ObjectSchema, string } from "yup";
import { ProductSelectFormError } from "./constants";

export interface FormSchema {
	productSelect?: string | null;
}
export const addLineItemSchemaObject = {
	productSelect: string().required(ProductSelectFormError)
} as const;

export const schema: ObjectSchema<FormSchema> = object(addLineItemSchemaObject);
