import styles from "pages/replacements-page-styles.module.css";
import { Divider, Grid } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { OrderDetailViewModel, OrderType } from "data/api/v1";
import {
	AccountNumberText,
	DesignerNumberText,
	OrderDateText,
	OrderReplacementsJobName,
	OrderReplacementsPONumber,
	UnavailableDataPlaceholderText,
	ReplacmentConfirmationOrderNumber,
	ReplacmentConfirmationOrderPending,
	ReplacementConfirmationLabel
} from "constants/text";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import SelectedReplacementReason from "./SelectedReplacementReason";
import { dateFormat } from "utils/date";
import { useGetUserDetailsQuery } from "features/api/userApi.ts";
import { ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";
import ReplacementShipTo from "./ReplacementShipTo";
import { ReplacementHeaderText } from "./ReplacementHeaderText.styles";
import { brandCheck, displayDesigner } from "utils/order";
import { ShippingDetailsHeaderWrapper } from "./ReplacementConfirmationOrderShippingDetails.styles";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import BillToBox from "components/Common/BillToShipToBox/BillToBox/BillToBox";
import { useGetAccountsByIdQuery, useGetBillToInfoQuery } from "features/api/accountApi.ts";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";

export interface ReplacementConfirmationOrderShippingDetailsProps {
	orderDetail: OrderDetailViewModel | null;
	accounts: CustomerAccountViewModel | undefined;
}

const ReplacementConfirmationOrderShippingDetails = ({
	orderDetail,
	accounts
}: ReplacementConfirmationOrderShippingDetailsProps) => {
	const cartItems = useSelector((state: RootState) => state.cart.replacements);
	const replacementOrder = useSelector((state: RootState) => state.submittingReplacementOrder.order);
	const { data: userDetails } = useGetUserDetailsQuery();
	const userAccountInformation: string = useMemo(() => {
		const accountMatch = userDetails?.user?.accounts?.find(
			(account) => account.number === orderDetail?.accountNumber
		);
		if (accountMatch) {
			return `${accountMatch.number}` + (accountMatch.description ? ` - ${accountMatch.description}` : "");
		} else {
			return "";
		}
	}, [userDetails, orderDetail]);

	const todayDate = useRef(new Date());

	const [billTo, setBillTo] = useState<BillToInfoViewModel | null>(null);

	const { data: account } = useGetAccountsByIdQuery(orderDetail?.orderId ?? "", { skip: !orderDetail?.orderId });

	const accountId = account?.[0]?.accountId;

	const { data: billToInfo } = useGetBillToInfoQuery(
		{ accountId: accountId ?? "", billToId: orderDetail?.billToId ?? "" },
		{ skip: !accountId || !orderDetail?.billToId }
	);

	useEffect(() => {
		if (billToInfo && !deepEqual(billTo, billToInfo)) {
			setBillTo(billToInfo);
		}
	}, [billTo, billToInfo]);

	const deepEqual = (obj1: any, obj2: any) => JSON.stringify(obj1) === JSON.stringify(obj2);

	return (
		<div className={styles.confirmationOrderContainer}>
			<ShippingDetailsHeaderWrapper>
				<p className="body2">{ReplacementConfirmationLabel}</p>
				<StyledSummaryCardLogo
					parentBrand={orderDetail?.parentBrand}
					alt={
						brandCheck(orderDetail?.parentBrand)
							? (orderDetail?.parentBrand ?? undefined)
							: "CabinetworksGroup Logo"
					}
					data-testid="replacement-confirmation-brand-logo"
					src={
						brandCheck(orderDetail?.parentBrand)
							? `/assets/manufacture_logos/${orderDetail?.parentBrand}.png`
							: "/assets/tandem_logos/cwg_logo.png"
					}
				/>
			</ShippingDetailsHeaderWrapper>

			<Grid
				container
				className={styles.replacementReasonHeader}
			>
				<Grid item>
					<span data-testid="replacement-confirmation-header">
						<ReplacementHeaderText
							className={styles.replacementConfirmationOrderNumber}
							data-testid="replacement-confirmation-order-number"
						>
							{ReplacmentConfirmationOrderNumber}
							<ReplacementHeaderText
								className={styles.replacementConfirmationOrderPending}
								data-testid="replacement-confirmation-pending"
							>
								&nbsp;
								{ReplacmentConfirmationOrderPending}
							</ReplacementHeaderText>
						</ReplacementHeaderText>
					</span>
				</Grid>
			</Grid>

			<Divider
				sx={{ margin: "1rem 0" }}
				data-testid="replacement-confirmation-divider"
			/>

			<Grid
				container
				sx={{ marginBottom: "16px" }}
			>
				<Grid
					item
					xs={12}
				>
					<span className="subtitle2">{OrderDateText}</span>
					<br />
					<span
						className="body2"
						data-testid="replacement-confirmation-order-date"
					>
						{dateFormat(String(todayDate.current))}
					</span>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<span className="subtitle2">{AccountNumberText}</span>
					<br />
					<span
						className="body2"
						data-testid="replacement-confirmation-account-information"
					>
						{(userAccountInformation.length ? userAccountInformation : orderDetail?.accountNumber) ||
							UnavailableDataPlaceholderText}
					</span>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<span className="subtitle2">{DesignerNumberText}</span>
					<br />
					<span
						className="body2"
						data-testid="replacement-confirmation-designer-number"
					>
						{displayDesigner(orderDetail?.designer) ?? UnavailableDataPlaceholderText}
					</span>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<span className="subtitle2">{OrderReplacementsJobName}</span>
					<br />
					<span
						className="body2"
						data-testid="replacement-confirmation-job-name"
					>
						{replacementOrder?.jobName ?? orderDetail?.jobName ?? UnavailableDataPlaceholderText}
					</span>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<span className="subtitle2">{OrderReplacementsPONumber}</span>
					<br />
					<span
						className="body2"
						data-testid="replacement-confirmation-po-number"
					>
						{replacementOrder?.poNumber ?? orderDetail?.poNumber ?? UnavailableDataPlaceholderText}
					</span>
				</Grid>
			</Grid>
			<BillToBox
				billTo={billTo}
				orderType={OrderType.REPLACEMENT}
				isNoChargeOrder
				hasBorder
				dataTestIdPrefix="replacement"
			/>
			<ReplacementShipTo
				accounts={accounts}
				orderDetail={orderDetail}
			/>
			{cartItems.map((item: ReplacementCartItem) => (
				<Grid
					container
					key={item.sku}
				>
					<SelectedReplacementReason item={item} />
				</Grid>
			))}
		</div>
	);
};

export default ReplacementConfirmationOrderShippingDetails;
