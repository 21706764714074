import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const AddGlobalsButton = styled(Button)({
	display: "flex",
	alignItems: "center",
	gap: "8px",
	fontWeight: 600,
	margin: 0,
	verticalAlign: "middle",
	letterSpacing: ".4px",
	lineHeight: "24px",
	textTransform: "uppercase"
});

export const AutoCompleteContainer = styled.div({
	display: "grid",
	gap: 16
});

export const AllFieldsRequired = styled.div(({ theme }) => ({
	...theme.project.typography.body.body2,
	color: theme.project.colors.fontColors.secondary
}));

export const Description = styled.div(({ theme }) => ({
	...theme.project.typography.caption,
	color: theme.project.colors.fontColors.secondary,
	paddingLeft: 24,
	paddingRight: 24,
	paddingTop: 4
}));

export const SelectionDetails = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle2,
	color: theme.project.colors.fontColors.main,
	fontSize: "0.875rem",
	lineHeight: "200%"
}));
