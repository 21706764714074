import { InferType, mixed, object, string } from "yup";
import {
	SelectAccountNumberError,
	SelectBillToNumberError,
	SelectConstructionError,
	SelectDoorStyleError,
	SelectFinishError,
	SelectPackagingError,
	SelectProductLineError,
	SelectShapeError,
	SelectWoodError
} from "../../constants";
import { ProductLineViewModel } from "data/api/v1/model/product-line-view-model";
import { StyleViewModel } from "data/api/v1/model/style-view-model";
import { FinishViewModel } from "data/api/v1/model/finish-view-model.ts";
import { SpeciesViewModel } from "data/api/v1/model/species-view-model";
import { ShapeViewModel } from "data/api/v1/model/shape-view-model";
import { ConstructionViewModel } from "data/api/v1/model/construction-view-model";
import { PackagingViewModel } from "data/api/v1/model/packaging-view-model";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";

export const schema = object({
	// StepOne
	productLine: mixed<ProductLineViewModel>().when("$isUpdate", {
		is: false,
		then: (schema) => schema.required(SelectProductLineError)
	}),

	doorStyle: mixed<StyleViewModel>().required(SelectDoorStyleError),

	// StepTwo
	shape: mixed<ShapeViewModel>().required(SelectShapeError),
	species: mixed<SpeciesViewModel>().required(SelectWoodError),
	finish: mixed<FinishViewModel>().required(SelectFinishError),

	// StepThree
	hinge: string(),
	caseColor: string(),
	construction: mixed<ConstructionViewModel>().required(SelectConstructionError),
	packaging: mixed<PackagingViewModel>().when("$hasPackaging", {
		is: true,
		then: (schema) => schema.required(SelectPackagingError)
	}),

	// StepFour
	accountNumber: mixed<CustomerAccountViewModel>().when("$isUpdate", {
		is: false,
		then: (schema) => schema.required(SelectAccountNumberError)
	}),
	billToNumber: mixed<BillToInfoViewModel>().when("$isUpdate", {
		is: false,
		then: (schema) => schema.required(SelectBillToNumberError)
	})
});

export type GlobalAttributeFields = InferType<typeof schema>;
