import { ObjectSchema, object, string, mixed, InferType } from "yup";
import { AddressRequiredText, PONumberRequiredText } from "constants/text";
import { shipToFormSchemaObject, type FormSchema as ShipToSchema } from "components/Replacements/ShipToForm/schema";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model";

export const PO_NUMBER_MAX_LENGTH = 25;
export const JOB_NAME_MAX_LENGTH = 30;

export interface FormSchema extends ShipToSchema {
	designer?: string | null;
	jobName?: string;
	poNumber?: string;
	address?: ShipToViewModel;
}

export const schema: ObjectSchema<FormSchema> = object({
	...shipToFormSchemaObject,
	designer: string().nullable(),
	jobName: string().max(JOB_NAME_MAX_LENGTH),
	poNumber: string().max(PO_NUMBER_MAX_LENGTH).required(PONumberRequiredText),
	address: mixed<ShipToViewModel>().when("isCustomerPickup", {
		is: true,
		then: (schema) => schema,
		otherwise: (schema) => schema.required(AddressRequiredText)
	})
});

export type ShippingDetailsSchema = InferType<typeof schema>;
