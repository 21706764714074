import styles from "../replacements-page-styles.module.css";
import ReplacementsHeader from "components/Replacements/ReplacementsHeader";
import ReplacementReview from "components/Replacements/ReplacementReview";
import ErrorBanner from "components/Replacements/ErrorBanner";
import { useParams } from "react-router-dom";
import { useGetByIdQuery } from "features/api/orderApi.ts";
import ReplacementReviewEditOrderDetails from "components/Replacements/ReplacementReviewEditOrderDetails";
import ReplacementReviewButtons from "components/Replacements/ReplacementReviewButtons";
import { useEffect, useState } from "react";
import OrderDetailsHeaderSkeleton from "components/Skeletons/OrderDetailsHeaderSkeleton";
import OrderDetailsSummarySkeleton from "components/Skeletons/OrderDetailsSummarySkeleton";
import OrderDetailsLineItemSkeleton from "components/Skeletons/OrderDetailsLineItemSkeleton";
import ReplacementShipTo from "components/Replacements/ReplacementShipTo";
import AddressFormProvider from "pages/Replacements/forms/AddressForm/AddressFormProvider";
import { APIErrorResponse } from "features/api/utils/apiUtils.ts";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { OrderType, ReplacementFormTracking } from "../../data/api/v1";
import { useGetAccountsByIdQuery, useGetBillToInfoQuery } from "features/api/accountApi.ts";
import BillToBox from "components/Common/BillToShipToBox/BillToBox/BillToBox";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";

const ReplacementReviewPage = () => {
	const params = useParams();
	const { data } = useGetByIdQuery(params?.orderId || "");
	const [postError, setPostError] = useState<APIErrorResponse["errors"]>();
	const editState = useSelector((state: RootState) => state.submittingReplacementOrder.editState);
	const editStateNone = editState === ReplacementFormTracking.NONE;

	const handleReplacementError = (error: APIErrorResponse["errors"]) => {
		setPostError(error);
	};
	const [billTo, setBillTo] = useState<BillToInfoViewModel | null>(null);

	const { data: accounts, isLoading } = useGetAccountsByIdQuery(params?.orderId || "");

	const accountId = accounts?.[0]?.accountId;
	const billToId = data?.billToId;

	const { data: billToInfo } = useGetBillToInfoQuery(
		{ accountId: accountId ?? "", billToId: billToId ?? "" },
		{ skip: !accountId || !billToId }
	);

	useEffect(() => {
		if (billToInfo) {
			setBillTo(billToInfo);
		}
	}, [billToInfo]);

	if (isLoading) {
		return (
			<div data-testid="order-detail-page-skeleton">
				<OrderDetailsHeaderSkeleton />
				<OrderDetailsSummarySkeleton />
				{Array(6)
					.fill(1)
					.map((index: any) => (
						<OrderDetailsLineItemSkeleton key={index} />
					))}
			</div>
		);
	}

	return (
		<div>
			<div className={styles.replacementsHeaderContainer}>
				<ReplacementsHeader orderDetail={data} />
			</div>
			{postError && (
				<div className={styles.replacementReasonContainer}>
					<ErrorBanner apiErrorResponse={postError} />
				</div>
			)}
			<div className={styles.replacementReasonContainer}>
				<ReplacementReview />
			</div>
			<div className={styles.replacementReasonContainer}>
				<AddressFormProvider>
					<BillToBox
						billTo={billTo}
						orderType={OrderType.REPLACEMENT}
						isNoChargeOrder
						dataTestIdPrefix="replacement"
					/>
					<ReplacementReviewEditOrderDetails orderDetail={data} />
					<ReplacementShipTo
						accounts={accounts?.[0]}
						orderDetail={data}
					/>
				</AddressFormProvider>
			</div>
			{editStateNone && (
				<div className={styles.replacementReasonContainer}>
					<ReplacementReviewButtons
						orderDetail={data}
						accounts={accounts?.[0]}
						handleReplacementError={handleReplacementError}
					/>
				</div>
			)}
		</div>
	);
};

export default ReplacementReviewPage;
