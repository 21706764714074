import { NewOrdersSubheaderWrapper, SaveDraftErrorBanner, SubmitDraft400Message } from "../NewOrders.styles";
import { NewDraft400ErrorText, SearchItemText } from "constants/text";
import { SyntheticEvent } from "react";
import SaveDraft from "../SaveDraft/SaveDraft";
import SuggestedSearch from "components/Common/SuggestedSearch/SuggestedSearch";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { DraftErrorTitle, NewOrderContactCare } from "../constants";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model";
import AddGlobalAttributes from "../AddGlobalAttributes/AddGlobalAttributes";
import GlobalFormProvider from "../AddGlobalAttributes/forms/GlobalFormProvider";

interface NewOrdersSubheaderProps {
	title: string;
	dataTestId: string;
	hasSearch?: boolean;
	handleSearch: (event: SyntheticEvent, values: string | null) => void;
	searchOptions?: Array<string>;
	searchValue?: string;
	autoCompleteId?: string;
	isLoading?: boolean;
	draftOrder: DraftOrderViewModel | undefined;
	hasAddGlobalAttributesButton?: boolean;
	isAddOn?: boolean;
}

const NewOrdersSubheader = ({
	title,
	dataTestId,
	hasSearch = false,
	handleSearch,
	isLoading,
	searchOptions,
	searchValue,
	autoCompleteId,
	draftOrder,
	hasAddGlobalAttributesButton = false,
	isAddOn = false
}: NewOrdersSubheaderProps) => {
	const isDraftError = useSelector((state: RootState) => state.submittingNewOrder.draftError);
	return (
		<div>
			<NewOrdersSubheaderWrapper
				isLoading={isLoading}
				data-testid={dataTestId}
			>
				<div>
					<h1>{title}</h1>
					<div>
						{hasAddGlobalAttributesButton && (
							<GlobalFormProvider>
								<AddGlobalAttributes />
							</GlobalFormProvider>
						)}
						{!isAddOn && <SaveDraft draftOrder={draftOrder} />}
						{hasSearch && (
							<SuggestedSearch
								ariaLabel={autoCompleteId ?? ""}
								dataTestId={autoCompleteId ?? ""}
								dropDownOpenOnChar={3}
								placeholder={SearchItemText}
								handleSearch={handleSearch}
								id={autoCompleteId ?? ""}
								searchOptions={searchOptions ?? []}
								searchValue={searchValue ?? ""}
								size="small"
							/>
						)}
					</div>
				</div>
			</NewOrdersSubheaderWrapper>
			{isDraftError && (
				<SaveDraftErrorBanner>
					<ErrorOutline />
					<SubmitDraft400Message>
						<h6>{DraftErrorTitle}</h6>
						<div>{NewDraft400ErrorText}</div>
						<ul>
							{isDraftError.data.errors.map((error: any, index: any) => (
								<li key={index}>{error.message}</li>
							))}
						</ul>
						<div>{NewOrderContactCare}</div>
						<div>({isDraftError?.status})</div>
					</SubmitDraft400Message>
				</SaveDraftErrorBanner>
			)}
		</div>
	);
};

export default NewOrdersSubheader;
