export const ProductSelectFormError = "Please select a product";

export const AddLineItemHeader = "Add Item";

export const AddLineItemRequired = "Required Field(*)";

export const AddLineItemProductPlaceholder = "Select item";

export const AddLineItemStandardDimensionsHeader = "Standard dimensions";

export const AddLineItemQuantityPlaceholder = "Qty";

export const AddLineDepthLabel = "Depth:";

export const AddLineHeightLabel = "Height:";

export const AddLineWidthLabel = "Width:";

export const AddLineCancelButton = "CANCEL";

export const AddLineSaveButton = "Save";

export const AddLineAddAnotherItemButton = "Add another item";

export const AddLineAddLineButtonText = "Add item";
export const AddLineEnterText = "Enter to add item";
export const AddLineAddAnotherTextOne = "Shift to add another item";
export const AddLineAddAnotherTextTwo = "add another item";
export const AddLineAddModificationButton = "Add modification";
export const AddLineItemInvalidProductError = "This is an invalid product";
