import { StaticComponentBackground, StaticComponentTitle } from "../NewOrders.styles";
import {
	NewOrderBuildGridDoorStyleColumnHeader,
	NewOrderBuildGridFinishColumnHeader,
	NewOrderBuildGridQuantityColumnHeader,
	NewOrderLineItemsInOrderText,
	NewOrderOrderListHeader,
	NewOrderSubtotalLabel,
	NewOrderViewPriceEstimateExtendedPriceColumnHeader,
	NewOrderViewPriceEstimateListPriceColumnHeader
} from "../constants";
import { ReviewOrderListHeader, ReviewOrderListLineItemContainer, ReviewOrderSubtotal } from "./reviewOrder.styles";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { FC, useMemo } from "react";
import { EstimatesLineItemGrid } from "../ViewPriceEstimates/viewPriceEstimates.styles";
import { UnavailableDataPlaceholderText } from "constants/text";
import Header from "../../Common/LineItemGrid/Header/Header";
import EstimatedSubtotal from "../ViewPriceEstimates/EstimatedSubtotal";
import { NewOrdersForReview } from "pages/OrderPages/ReviewOrderPage/ReviewOrderPage";
import { createViewPriceEstimates } from "components/NewOrders/utils/NewOrderUtils";
import Modification from "components/Common/LineItemGrid/Modification/Modification";
import ReviewOrderAccountBillTo from "../ReviewOrderAccountBillTo/ReviewOrderAccountBillTo";

const ReviewOrderList: FC = () => {
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);
	const newOrderDetails = useSelector((state: RootState) => state.submittingNewOrder.reviewOrderDisplay);
	const priceEstimates = useMemo(() => {
		if (parsedCSV !== undefined && validatedOrderResponse !== undefined) {
			return createViewPriceEstimates(parsedCSV, validatedOrderResponse);
		}
	}, [parsedCSV, validatedOrderResponse]);

	const priceEstimateConfig = useMemo(() => {
		return priceEstimates?.configurations.map((priceEstimateConfiguration) => priceEstimateConfiguration);
	}, [priceEstimates]);

	const newOrderConfiguration = useMemo(() => {
		return newOrderDetails?.configurations?.map((config) => config);
	}, [newOrderDetails]);

	const newOrderForReview: NewOrdersForReview[] | undefined = useMemo(
		() =>
			newOrderConfiguration?.map((config, index) => ({
				...config,
				style: priceEstimateConfig?.[index]?.doorStyle ?? "",
				finish: priceEstimateConfig?.[index]?.finish ?? "",
				lineItems: config.lineItems?.map((item, i) => ({
					...item,
					doorStyle: priceEstimateConfig?.[index]?.doorStyle,
					finish: priceEstimateConfig?.[index]?.finish,
					modifications: priceEstimateConfig?.[index]?.items?.[i]?.modifications
				})),
				priceEstimateConfig: priceEstimateConfig?.[index]
			})),
		[newOrderConfiguration, priceEstimateConfig]
	);

	return (
		<>
			<StaticComponentBackground>
				<StaticComponentTitle data-testid="new-order-review-order-list-title">
					{NewOrderOrderListHeader}
				</StaticComponentTitle>
				{newOrderForReview?.map((config) => (
					<>
						<ReviewOrderListHeader key={config.accountId}>
							<span data-testid="new-order-build-label">
								<div>
									{config?.priceEstimateConfig?.productLine}, {config.style}, {config.species},{" "}
									{config.finish}
								</div>
								<div>
									{config?.priceEstimateConfig?.shape}, {config.construction}, {config.packaging}
								</div>
							</span>
							<span data-testid="new-order-review-order-list-line-items">
								{config?.lineItems?.length} {NewOrderLineItemsInOrderText}
							</span>
						</ReviewOrderListHeader>
						<ReviewOrderAccountBillTo newOrderConfig={config} />
						{config?.lineItems?.map((item) => (
							<ReviewOrderListLineItemContainer key={item?.lineItemNumber}>
								<EstimatesLineItemGrid
									mainRowContent={[
										<Header
											description={item.description ?? UnavailableDataPlaceholderText}
											key={config.configurationId}
											header={item.sku ?? UnavailableDataPlaceholderText}
											lineNumber={String(item.lineItemNumber)}
										/>,
										<div key={`${String(item.lineItemNumber)}-door-style`}>
											<div>{NewOrderBuildGridDoorStyleColumnHeader}</div>
											<div data-testid="new-order-review-order-list-line-item-doorStyle">
												{item.doorStyle}
											</div>
										</div>,
										<div key={`${String(item.lineItemNumber)}-finish`}>
											<div>{NewOrderBuildGridFinishColumnHeader}</div>
											<div data-testid="new-order-review-order-list-line-item-finish">
												{item.finish}
											</div>
										</div>,
										<div key={`${item.lineItemNumber}-qty`}>
											<div>{NewOrderBuildGridQuantityColumnHeader}</div>
											<div data-testid="new-order-review-order-list-line-item-quantity">
												{item.quantityOrdered}
											</div>
										</div>,
										<div key={`${String(item.lineItemNumber)}-list-price`}>
											<div>{NewOrderViewPriceEstimateListPriceColumnHeader}</div>
											<div data-testid="new-order-review-order-list-line-item-listPrice">
												{item?.listPrice?.toLocaleString("en-US", {
													style: "currency",
													currency: "USD"
												})}
											</div>
										</div>,
										<div key={`${String(item.lineItemNumber)}-extended-price`}>
											<div>{NewOrderViewPriceEstimateExtendedPriceColumnHeader}</div>
											<div data-testid="new-order-review-order-list-line-item-extendedPrice">
												{item?.extendedPrice?.toLocaleString("en-US", {
													style: "currency",
													currency: "USD"
												})}
											</div>
										</div>
									]}
									modificationRows={item.modifications?.map((modification) => (
										<Modification
											description={modification.description ?? undefined}
											key={modification.userCode}
											extendedPrice={modification.extendedPrice}
											lineNumber={modification.lineNumber}
											listPrice={modification.listPrice}
											title={modification.userCode ?? undefined}
										/>
									))}
								/>
							</ReviewOrderListLineItemContainer>
						))}

						<ReviewOrderSubtotal>
							<span>{NewOrderSubtotalLabel}</span>
							<span data-testid="new-order-review-order-list-extendedPrice-configuration">
								{config.priceEstimateConfig?.pricing.extendedPrice.toLocaleString("en-US", {
									style: "currency",
									currency: "USD"
								})}
							</span>
						</ReviewOrderSubtotal>
					</>
				))}
			</StaticComponentBackground>
			<EstimatedSubtotal
				data-testid="new-order-review-order-list-extendedPrice-full-order"
				subtotal={priceEstimates?.pricing.extendedPrice ?? 0}
			/>
		</>
	);
};

export default ReviewOrderList;
