import { FilterOpts } from "pages/OrderPages/OrderListPage/OrderListPage.tsx";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model.ts";
import { NoDesignerText } from "constants/text.ts";

export const displayDraftDesigner = (designerName: any) => {
	if (designerName === "--") {
		return NoDesignerText;
	}
	return designerName;
};

const getDraftValue = (item: DraftOrderViewModel, key: string) => {
	switch (key) {
		case "account": {
			const accountValue = item.configurations?.map((item) => {
				return `${item.accountNumber} - ${item.accountName?.toUpperCase()}`;
			});

			return accountValue ?? [];
		}
		case "designerName":
			return item?.designerName ?? "Designer not identified";
		default:
			// default is empty because we have accounted for possible cases
			return "";
	}
};

const getCleanFilterValues = (key: string, selectedFilterValues: string[]): string[] => {
	return selectedFilterValues.map((fv: string) => {
		if (fv === "Designer not identified") {
			return "--";
		}
		fv = fv.trim();
		return fv.toUpperCase();
	});
};

export const getFilteredDrafts = (filterOptions: FilterOpts, drafts: DraftOrderViewModel[]): DraftOrderViewModel[] => {
	const { designers, accounts } = filterOptions;

	const compare = (key: string, selectedFilterValues: string[], item: DraftOrderViewModel) => {
		const cleanFilterValues = getCleanFilterValues(key, selectedFilterValues);

		const draftedValues = getDraftValue(item, key);
		if (Array.isArray(draftedValues)) {
			return draftedValues.some((value) => cleanFilterValues.includes(value?.trim()));
		}
		return cleanFilterValues.includes(draftedValues.toUpperCase());
	};

	return (
		Object.entries({
			designerName: designers,
			account: accounts
		})
			?.filter((x) => x[1].length > 0)
			?.reduce((a: any[], c: any) => a?.filter((x) => compare(c[0], c[1], x)), drafts) || []
	);
};
