import { AutoCompleteContainer, SelectionDetails } from "../addGlobalAttributes.styles";
import { Controller, useFormContext } from "react-hook-form";
import AutoComplete from "../../../Common/Autocomplete/Autocomplete";
import { GlobalAttributeFields } from "../forms/schema";
import { SelectConstruction, SelectPackaging } from "../../constants";
import { useLazyGetFinishDataQuery } from "features/api/globalsApi.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useEffect } from "react";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { sortGlobalByDescription } from "components/NewOrders/utils/NewOrderUtils";

interface StepThreeProps {
	isUpdate?: boolean;
}

const StepThree = ({ isUpdate }: StepThreeProps) => {
	const { control, watch, clearErrors, setValue } = useFormContext<GlobalAttributeFields>();
	const selectedProductLine = watch("productLine");
	const selectedDoorStyle = watch("doorStyle");
	const selectedShape = watch("shape");
	const selectedSpecies = watch("species");
	const selectedFinish = watch("finish");
	const selectedFinishId = selectedFinish?.id;
	const dispatch = useDispatch();
	const updatedGlobalAttributes = useSelector((state: RootState) => state.newOrder.updatedGlobalAttributes);

	const [trigger, { data: finishData, isLoading }] = useLazyGetFinishDataQuery();

	// Fetch the finish only once for the lifecycle

	useEffect(() => {
		if (selectedFinishId) {
			trigger(selectedFinishId);
		}
	}, [selectedFinishId, trigger]);

	useEffect(() => {
		if (!isUpdate) {
			return;
		}

		const constructionValue = finishData?.constructions?.find(
			(construction) => construction.id === updatedGlobalAttributes?.constructionId
		);

		if (constructionValue) {
			setValue("construction", constructionValue);
		}

		const packagingValue = finishData?.packagings?.find(
			(packaging) => packaging.id === updatedGlobalAttributes?.packagingId
		);

		if (packagingValue) {
			setValue("packaging", packagingValue);
		}
	}, [
		finishData?.constructions,
		finishData?.packagings,
		isUpdate,
		setValue,
		updatedGlobalAttributes?.constructionId,
		updatedGlobalAttributes?.packagingId
	]);

	const constructionOptions = finishData?.constructions ?? [];
	const packagingOptions = finishData?.packagings ?? [];

	return (
		<AutoCompleteContainer>
			<SelectionDetails data-testid="global-attribute-modal-selection">{`${isUpdate ? updatedGlobalAttributes?.productLine : selectedProductLine?.description}, ${selectedDoorStyle?.description}, ${selectedShape?.description}, ${selectedSpecies?.description}, ${selectedFinish?.description}`}</SelectionDetails>
			{constructionOptions.length !== 0 && (
				<Controller
					name="construction"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disableClearable
							label={SelectConstruction}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated = {
										construction: value.description,
										constructionCode: value.code,
										constructionId: value.id
									};

									dispatch(newOrderActions.updateGlobalAttributes(globalAttributesToBeUpdated));
								}
								clearErrors("construction");
								onChange(value);
							}}
							options={sortGlobalByDescription(constructionOptions)}
							dataTestId="select-construction"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
			{packagingOptions.length !== 0 && (
				<Controller
					name="packaging"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disableClearable
							label={SelectPackaging}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated = {
										packaging: value.description,
										packagingCode: value.code,
										packagingId: value.id
									};
									dispatch(newOrderActions.updateGlobalAttributes(globalAttributesToBeUpdated));
								}
								clearErrors("packaging");
								onChange(value);
							}}
							options={sortGlobalByDescription(packagingOptions)}
							dataTestId="select-packaging"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
		</AutoCompleteContainer>
	);
};

export default StepThree;
