import {
	BuildNewOrderLineItemsContainer,
	NewOrdersButtonWrapper,
	NewOrdersLayoutContainer,
	NewOrdersContentContainer
} from "components/NewOrders/NewOrders.styles";
import { BackText, ContinueText, UnavailableDataPlaceholderText } from "constants/text";
import { NewOrderShippingDetailsLabel } from "components/NewOrders/constants";
import ShippingOrderDetails from "components/NewOrders/ShippingOrderDetails";
import { useFormContext } from "react-hook-form";
import { useGetDesignersByAccountIdQuery } from "features/api/designerApi.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import ShipToForm from "components/ShipToForm/ShipToForm";
import { useState, useMemo, useEffect, RefObject } from "react";
import { AddressFormFields } from "pages/Replacements/forms/AddressForm/schema";
import {
	CreatePendingOrderRequestPendingLineItemViewModel,
	CreatePendingOrderRequestPendingOrderConfigurationViewModel,
	OrderType
} from "data/api/v1";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NewOrdersSubheader from "components/NewOrders/NewOrdersSubheader/NewOrdersSubheader";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";
import { ImportOrderViewModel } from "data/api/v1/model/import-order-view-model";
import { createViewPriceEstimates } from "components/NewOrders/utils/NewOrderUtils";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { OrderValidationViewModel } from "data/api/v1/model/order-validation-view-model";
import {
	ViewPriceEstimatesBaseItem,
	ViewPriceEstimatesLineItem
} from "components/NewOrders/ViewPriceEstimates/view-price-estimates-model";
import { BillToShipToBody, useNewOrderShipToAddressesMutation } from "features/api/newOrderApi.ts";
import { shippingDetailsActions } from "features/reducers/newOrder/shippingDetails";

const NewOrderShippingDetailsPage = () => {
	const shippingDetails = useSelector((state: RootState) => state.shippingDetails);
	const [contentOffset, setContentOffset] = useState(0);
	const calcHeightOfHeaderForContentTopOffset = (headerContainer: RefObject<HTMLDivElement>) => {
		setContentOffset(headerContainer.current?.clientHeight ?? 0);
	};
	const dispatch = useDispatch();
	const navigateTo = useNavigate();
	const addedAddresses = useSelector((state: RootState) => state.newOrder.newAddresses);
	const parsedCSV: ImportOrderViewModel = useSelector(
		(state: RootState) => state.newOrder.parsedCSV
	) as ImportOrderViewModel;
	const submittingOrderFromRedux = useSelector((state: RootState) => state.submittingNewOrder.newOrder);
	const csvConfigurations = useMemo(() => {
		return parsedCSV?.configurations?.map((config) => config ?? []);
	}, [parsedCSV]);
	const accountNumber = parsedCSV?.configurations?.[0]?.accountNumber;
	const accountNumberId = accountNumber?.accountId ?? null;
	const draftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);
	const [draft, setDraft] = useState(draftOrder);

	const { data: designers } = useGetDesignersByAccountIdQuery(accountNumberId ?? "");
	const storedDraftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);
	const isCustomerPickupAllowed = Boolean(accountNumber?.customerPickupAllowed);
	const allowLabelComments = Boolean(accountNumber?.allowLabelComments);
	const validatedOrderResponse: OrderValidationViewModel = useSelector(
		(state: RootState) => state.newOrder.validatedOrderResponse
	) as OrderValidationViewModel;
	const priceEstimates = useMemo(() => {
		return createViewPriceEstimates(parsedCSV, validatedOrderResponse);
	}, [parsedCSV, validatedOrderResponse]);

	const [newOrderShipToAddresses, { data: addressData, isLoading: addressesLoading }] =
		useNewOrderShipToAddressesMutation();
	// ADDRESS FROM BILL TO SHIPTO API CALL

	const convertViewPriceEstimatesLineItemToPendingLineItemView = (
		item: ViewPriceEstimatesLineItem | ViewPriceEstimatesBaseItem,
		configIndex: number
	): CreatePendingOrderRequestPendingLineItemViewModel => ({
		description: item.description ?? "",
		name: "",
		finish: csvConfigurations?.[configIndex]?.finishCode,
		doorStyle: csvConfigurations?.[configIndex]?.doorStyleCode,
		listPrice: item.listPrice,
		extendedPrice: item.extendedPrice,
		lineItemNumber: item.lineNumber,
		productLine: csvConfigurations?.[configIndex]?.productLineCode ?? "",
		quantityOrdered: item.quantity,
		sku: item.userCode,
		construction: csvConfigurations?.[configIndex]?.construction ?? "",
		hinge: "",
		woodSpecies: csvConfigurations?.[configIndex]?.species,
		id: item.itemKey,
		hasAttachments: false,
		cubeCount: item.cubeCount,
		cabinetCount: item.cabinetCount,
		accessoryCount: item.accessoryCount
	});

	const validatedLineItems: CreatePendingOrderRequestPendingLineItemViewModel[][] | undefined = useMemo(
		() =>
			priceEstimates.configurations?.map((priceEstimatesConfiguration) =>
				priceEstimatesConfiguration.items.map((item, index) => ({
					description: item.description ?? "",
					name: "",
					doorStyle: csvConfigurations?.[index]?.doorStyleCode,
					finish: csvConfigurations?.[index]?.finishCode,
					unitPrice: item.listPrice,
					listPrice: item.listPrice,
					extendedPrice: item.extendedPrice,
					lineItemNumber: item.lineNumber,
					productLineCode: priceEstimatesConfiguration.productLineCode,
					productLine: priceEstimatesConfiguration.productLine,
					quantityOrdered: item.quantity,
					sku: item.userCode,
					construction: priceEstimatesConfiguration.construction,
					hinge: "",
					woodSpecies: csvConfigurations?.[index]?.species,
					id: item.itemKey,
					updatedAt: String(new Date()),
					defectCode: null,
					hasAttachments: false,
					cubeCount: item.cubeCount,
					cabinetCount: item.cabinetCount,
					accessoryCount: item.accessoryCount
				}))
			),
		[priceEstimates, csvConfigurations]
	);

	const configurations: CreatePendingOrderRequestPendingOrderConfigurationViewModel[] | undefined = useMemo(
		() =>
			priceEstimates.configurations?.map((config, index: number) => ({
				configurationId: submittingOrderFromRedux?.configurations
					? submittingOrderFromRedux?.configurations[index].configurationId
					: "",
				brand: config.brand,
				productLineCode: config.productLineCode ?? "",
				style: config.doorStyleCode,
				finish: config.finishCode,
				species: config.species,
				construction: config.construction,
				packaging: config.packaging,
				accountId: config?.accountNumber?.accountId ?? null,
				lineItems: validatedLineItems ? validatedLineItems[index] : [],
				billToId: config?.billToNumber?.billToId ?? null,
				subTotal: config.pricing.extendedPrice,
				doorHardware: undefined, // the door and drawer fields may eventually be provided through CSV parsing
				drawerHardware: undefined,
				drawerFrontConstruction: undefined
			})),
		[priceEstimates.configurations, submittingOrderFromRedux?.configurations, validatedLineItems]
	);

	const [combinedAddresses, setCombinedAddresses] = useState<ShipToViewModel[]>([]);

	useEffect(() => {
		if (!addressesLoading && addressData === undefined) {
			const allIds = parsedCSV?.configurations?.map((config): BillToShipToBody => {
				return {
					accountId: String(config.accountNumber?.accountId),
					billToId: String(config.billToNumber?.billToId)
				};
			});

			if (allIds) {
				newOrderShipToAddresses(allIds)
					.unwrap()
					.catch((err) => err);
			}
		}
	}, [addressData, addressesLoading, newOrderShipToAddresses, parsedCSV?.configurations]);

	const shipToAddresses: ShipToViewModel[] | undefined = useMemo(
		() =>
			addressData?.map((address) => ({
				shipToId: address.shipToId,
				isActive: address.isActive ?? false,
				isSelected: address.isSelected ?? false,
				address: {
					name: address.address.name ?? null,
					line1: address.address.line1,
					line2: address.address.line2,
					line3: address.address.line3,
					phoneNumber: address.address.phoneNumber,
					email: address.address.email,
					city: address.address.city,
					state: address.address.state,
					zip: address.address.zip,
					county: address.address.county
				}
			})),
		[addressData]
	);

	useEffect(() => {
		if (!addressesLoading && shipToAddresses) {
			setCombinedAddresses([...addedAddresses, ...shipToAddresses]);
		}
	}, [addressesLoading, shipToAddresses, addedAddresses]);

	const [shippingInformation, setShippingInformation] = useState<ShipToViewModel | null>();

	useEffect(() => {
		if (combinedAddresses.length > 0) {
			const [firstShipToAddress] = combinedAddresses;
			setShippingInformation(firstShipToAddress);
		}
	}, [combinedAddresses]);

	const { handleSubmit, watch, getValues } = useFormContext();
	const jobName = watch("jobName");
	const poNumber = watch("poNumber");
	const designer = watch("designer");
	const address = watch("address");
	const email = watch("email");
	const isCustomerPickup = watch("isCustomerPickup");
	const phoneNumber = watch("phoneNumber");
	const requestedDeliveryDate = watch("requestedDeliveryDate");
	const deliveryInstructions = watch("deliveryInstructions");
	useEffect(() => {
		setDraft({
			...draftOrder,
			jobName,
			poNumber,
			designerName: designer?.replaceAll('"', "") ?? UnavailableDataPlaceholderText,
			designerNumber: designer?.replaceAll('"', "") ?? UnavailableDataPlaceholderText,
			isCustomerPickup: isCustomerPickup,
			shipToAddress: {
				name: !isCustomerPickup ? address?.address?.name : undefined,
				line1: !isCustomerPickup ? address?.address?.line1 : undefined,
				line2: !isCustomerPickup ? address?.address?.line2 : undefined,
				line3: !isCustomerPickup ? address?.address?.line3 : undefined,
				mailStop: !isCustomerPickup ? address?.address.mailStop : undefined,
				phoneNumber: phoneNumber ?? undefined,
				email: email ?? undefined,
				city: !isCustomerPickup ? (address?.address.city ?? null) : undefined,
				state: !isCustomerPickup ? (address?.address.state ?? null) : undefined,
				zip: !isCustomerPickup ? (address?.address.zip ?? null) : undefined,
				county: !isCustomerPickup ? (address?.address.county ?? null) : undefined
			},
			shipToId: !isCustomerPickup ? address?.shipToId : undefined,
			requestedDeliveryDate: requestedDeliveryDate,
			labelComments: deliveryInstructions
		});
	}, [
		jobName,
		poNumber,
		designer,
		address,
		email,
		isCustomerPickup,
		phoneNumber,
		requestedDeliveryDate,
		deliveryInstructions,
		draftOrder
	]);

	const handleAddNewAddressModalSubmit = (address: AddressFormFields) => {
		const shippingAddress: ShipToViewModel = {
			shipToId: null,
			isActive: true,
			isSelected: true,
			address: {
				name: address.fullName,
				email: address.email,
				phoneNumber: address.contactPhoneNumber,
				line1: address.address,
				line2: address.address2,
				line3: null,
				city: address.city,
				state: address.state,
				zip: address.zip,
				county: address.county
			}
		};
		const updatedAddresses = combinedAddresses.map((addr) => ({
			...addr,
			isSelected: false
		}));

		const newCombinedAddresses = [...updatedAddresses, shippingAddress];

		setCombinedAddresses(newCombinedAddresses);
		dispatch(newOrderActions.addNewAddress(shippingAddress));
		setShippingInformation(shippingAddress);
	};

	const handleSetShippingInformation = (address: ShipToViewModel | undefined) => {
		setShippingInformation(address);
	};

	const handleOnCancel = () => {
		saveShippingDetailsFormValuesToRedux();
		dispatch(submittingNewOrderActions.clearDraftError());
		dispatch(submittingNewOrderActions.updateDraftOrder(draft));
		navigateTo("/new-order/view-price-estimates");
	};

	const saveShippingDetailsFormValuesToRedux = () => {
		const shippingFormValues = getValues();
		dispatch(shippingDetailsActions.updateShippingDetails(shippingFormValues));
	};

	const handleOnSave = (values: any) => {
		saveShippingDetailsFormValuesToRedux();
		dispatch(submittingNewOrderActions.clearDraftError());
		const formattedDeliveryDate = `${values.requestedDeliveryDate}T00:00:00.000Z`;

		const flatLineItems = priceEstimates.configurations?.map((priceEstimatesConfiguration, index) =>
			priceEstimatesConfiguration.items.reduce(
				(accum: CreatePendingOrderRequestPendingLineItemViewModel[], item) => {
					const lineItem = convertViewPriceEstimatesLineItemToPendingLineItemView(item, index);
					const mods = item.modifications.map((mod) =>
						convertViewPriceEstimatesLineItemToPendingLineItemView(mod, index)
					);
					accum.push(lineItem);
					accum.push(...mods);
					return accum;
				},
				[]
			)
		);
		const configurationsWithFlatMods = configurations?.map((config, configIndex) => ({
			...config,
			lineItems: flatLineItems[configIndex]
		}));

		const isCustomerPickup = Boolean(values.isCustomerPickup);

		const submittingOrder = {
			tandemOrderId: submittingOrderFromRedux?.tandemOrderId,
			jobName: values.jobName,
			poNumber: values.poNumber,
			orderType: OrderType.ORIGINAL,
			designerNumber: values.designer?.replaceAll('"', "") ?? "",
			labelComments: values.deliveryInstructions,
			requestedDeliveryDate: values.requestedDeliveryDate ? formattedDeliveryDate : null,
			originalOrderId: null,
			shipToId: !isCustomerPickup ? shippingInformation?.shipToId : undefined,
			isCustomerPickup: values.isCustomerPickup ?? false,
			shipToAddress: {
				name: !isCustomerPickup ? shippingInformation?.address?.name : undefined,
				line1: !isCustomerPickup ? shippingInformation?.address?.line1 : undefined,
				line2: !isCustomerPickup ? shippingInformation?.address?.line2 : undefined,
				line3: !isCustomerPickup ? shippingInformation?.address?.line3 : undefined,
				mailStop: !isCustomerPickup ? shippingInformation?.address.mailStop : undefined,
				phoneNumber: values.phoneNumber ?? undefined,
				email: values.email ?? undefined,
				city: !isCustomerPickup ? shippingInformation?.address.city : undefined,
				state: !isCustomerPickup ? shippingInformation?.address.state : undefined,
				zip: !isCustomerPickup ? shippingInformation?.address.zip : undefined,
				county: !isCustomerPickup ? shippingInformation?.address.county : undefined
			},
			configurations: configurationsWithFlatMods
		};
		const draftOrder = {
			...submittingOrder,
			configurations,
			draftOrderId: storedDraftOrder?.draftOrderId,
			draftName: storedDraftOrder?.draftName,
			designerName: values.designer?.replaceAll('"', "") ?? UnavailableDataPlaceholderText
		};
		dispatch(submittingNewOrderActions.submittingNewOrder(submittingOrder));
		dispatch(submittingNewOrderActions.reviewOrderDisplay({ ...submittingOrder, configurations }));
		dispatch(submittingNewOrderActions.updateDraftOrder(draftOrder));
		navigateTo("/new-order/review-order");
	};

	return (
		<form
			onSubmit={handleSubmit(handleOnSave)}
			noValidate
		>
			<NewOrdersLayoutContainer>
				<NewOrdersHeader
					getContainerElement={calcHeightOfHeaderForContentTopOffset}
					draftOrder={draft}
				/>
				<NewOrdersContentContainer marginTopOffset={contentOffset}>
					<BuildNewOrderLineItemsContainer>
						<NewOrdersSubheader
							title={NewOrderShippingDetailsLabel}
							dataTestId="new-order-shipping-label"
							handleSearch={() => {}}
							draftOrder={draft}
						/>

						<ShippingOrderDetails designers={designers} />

						<ShipToForm
							allowLabelComments={allowLabelComments}
							analyticsDataId="new-order-shipping-details-new-address-link"
							combinedAddresses={combinedAddresses}
							defaultFormValues={null}
							customerPickupAllowed={isCustomerPickupAllowed ?? false}
							isCustomerPickupDefault={shippingDetails?.isCustomerPickup ?? false}
							handleAddNewAddressModalSubmit={handleAddNewAddressModalSubmit}
							handleOnCancel={handleOnCancel}
							handleSetShippingInformation={handleSetShippingInformation}
							handleOnSave={handleOnSave}
							showCancelSaveButtons={false}
							hasFormContext
						/>
					</BuildNewOrderLineItemsContainer>
					<NewOrdersButtonWrapper>
						<Button
							variant="text"
							data-testid="newOrderShippingDetails-backButton"
							onClick={handleOnCancel}
						>
							{BackText}
						</Button>
						<Button
							fullWidth
							variant="contained"
							data-testid="newOrderShippingDetails-continueButton"
							data-id="newOrderShippingDetails-continueButton"
							type="submit"
						>
							{ContinueText}
						</Button>
					</NewOrdersButtonWrapper>
				</NewOrdersContentContainer>
			</NewOrdersLayoutContainer>
		</form>
	);
};

export default NewOrderShippingDetailsPage;
