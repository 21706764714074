import { checkboxClasses } from "@mui/material/Checkbox";
import FormControlLabel, { formControlLabelClasses } from "@mui/material/FormControlLabel";
import { listClasses } from "@mui/material/List";
import { paperClasses } from "@mui/material/Paper";
import { svgIconClasses } from "@mui/material/SvgIcon";
import { typographyClasses } from "@mui/material/Typography";
import styled from "@emotion/styled";
import Chip, { chipClasses } from "@mui/material/Chip";
import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MuiWarningAmber from "@mui/icons-material/WarningAmber";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

interface CommonProps {
	item: string | { value: string; error: boolean };
}

interface MenuProps {
	isMobile?: boolean;
}

interface MenuItemListWrapperProps {
	isSearchable: boolean | undefined;
	child: React.ReactNode;
}

export const Menu = styled(MuiMenu, { shouldForwardProp: (prop) => prop !== "isMobile" })<MenuProps>(({
	isMobile = false
}) => {
	const defaultMenuHeight = "500px";
	const defaultMenuMaxWidth = isMobile ? "320px" : "400px";
	const defaultMenuMinWidth = "320px";

	return {
		height: defaultMenuHeight,
		width: "auto",
		[`.${listClasses.root}`]: {
			color: "var(--text-primary)",
			padding: ".5rem"
		},
		[`.${paperClasses.root}`]: {
			width: defaultMenuMaxWidth,
			minWidth: defaultMenuMinWidth
		},
		[`.${typographyClasses.root}`]: {
			fontFamily: '"Gibson Regular", sans-serif'
		}
	};
});

export const MenuItemListWrapper = styled.div<MenuItemListWrapperProps>(({ isSearchable, child }) => ({
	maxHeight: isSearchable || child ? "328px" : "384px",
	overflowY: "auto"
}));

export const MenuItemWrapper = styled(MenuItem)({
	padding: 0,
	whiteSpace: "unset",
	wordBreak: "break-word"
});

export const MenuItemContentWrapper = styled.div<CommonProps>(({ item }) => ({
	alignItems: "center",
	backgroundColor: typeof item !== "string" && item.error ? "var(--orange-50)" : "",
	boxSizing: "border-box",
	display: "flex",
	justifyContent: "space-between",
	width: "calc(100%)",
	[`.${formControlLabelClasses.root}`]: {
		color: typeof item !== "string" && item.error ? "var(--orange-500)" : "",
		overflow: "hidden",
		margin: "0",
		width: "100%",
		[`.${checkboxClasses.root}`]: {
			alignSelf: "flex-start",
			[`& .${svgIconClasses.root}[data-testid='CheckBoxIcon']`]: {
				fill: typeof item !== "string" && item.error ? "var(--orange-500)" : "var(--cwg-blue-900)"
			}
		}
	}
}));

export const WarningAmber = styled(MuiWarningAmber)({
	color: "var(--orange-500)",
	marginRight: ".25rem"
});

export const FilterSearchWrapper = styled.div({
	width: "calc(100% - 1.5rem)",
	padding: ".5rem .75rem",
	height: "2.5rem",
	backgroundColor: "var(--white-50)",
	zIndex: 10000
});

export const FilterSearch = styled(TextField)({
	"& .MuiFormLabel-root": {
		fontFamily: "Gibson Regular"
	},
	"& .MuiInputBase-input": {
		fontFamily: "Gibson Regular",
		color: "var(--text-primary)"
	}
});

export const OpenOrdersToggleWrapper = styled(MenuItem)({
	padding: ".5rem 0",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "relative",
	width: "100%"
});

export const OpenOrdersToggleDivider = styled(Divider)({
	margin: ".5rem 0",
	background: "var(--gray-300)",
	width: "100%"
});

interface StyledChipProps {
	hasWarning: boolean | undefined;
}

export const StyledChip = styled(Chip, {
	shouldForwardProp: (prop) => prop !== "hasWarning"
})<StyledChipProps>(({ hasWarning }) => ({
	marginRight: "0.5rem",
	...(!hasWarning && {
		[`&.${chipClasses.root}`]: {
			"&:hover": {
				color: "var(--cwg-blue-900)",
				borderColor: "var(--chip-selected)"
			}
		}
	}),
	[`.${chipClasses.label}`]: {
		paddingTop: "3px",
		fontSize: ".938rem",
		maxWidth: "200px"
	},
	[`.${chipClasses.deleteIcon}`]: {
		...(hasWarning && {
			color: "var(--orange-500)",
			":hover": {
				color: "var(--orange-500)"
			}
		}),
		...(!hasWarning && {
			color: "var(--cwg-blue-900)",
			"&:hover": {
				color: "var(--cwg-blue-900)"
			}
		})
	},
	[`&.${chipClasses.colorPrimary}`]: {
		backgroundColor: "var(--white-50)",
		borderStyle: "solid",
		borderWidth: 1,
		fontWeight: 500,
		...(hasWarning && {
			color: "var(--orange-500)",
			borderColor: "var(--orange-500)"
		}),
		...(!hasWarning && {
			color: "var(--text-primary)",
			borderColor: "var(--gray-400)"
		})
	},
	[`&.${chipClasses.colorSecondary}`]: {
		borderWidth: 1,
		borderStyle: "solid",
		fontWeight: 500,
		...(hasWarning && {
			color: "var(--orange-500)",
			backgroundColor: "var(--orange-50)",
			borderColor: "var(--orange-100)"
		}),
		...(!hasWarning && {
			color: "var(--cwg-blue-900)",
			backgroundColor: "var(--chip-selected)",
			borderColor: "var(--chip-selected)"
		})
	}
}));

export const InactiveAccountOptions = styled(FormControlLabel)({
	fontStyle: "italic",
	fontSize: "var(--font-size-3)",
	lineHeight: "var(--line-height-8)",
	letterSpacing: "var(--letter-spacing-6)"
});

export const InactiveAccountsHeader = styled.div({
	padding: "1rem 1rem 0.5rem 1rem"
});

export const InactiveAccountsDivider = styled(Divider)({
	margin: "0 1rem"
});

export const ChipDropdownMenuSearchNoResultsWrapper = styled.div({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center",
	height: "calc(400px - 2.5rem)",
	marginTop: "-2rem",
	img: {
		width: "170px"
	},
	h5: {
		margin: "-1rem 0 .5rem 0",
		color: "var(--cwg-blue-900)"
	},
	p: {
		margin: "0 1rem"
	}
});
