import { MouseEvent, useEffect, useState } from "react";
import { GlobalAttributeFields } from "./forms/schema";
import { useFormContext } from "react-hook-form";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";
import StepFour from "./components/StepFour";
import { useDispatch, useSelector } from "react-redux";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { ImportConfigurationViewModel } from "data/api/v1";
import { Button, DialogContent } from "@mui/material";
import AddGlobalAttributesDialog from "../AddGlobalAttributesDialog";
import { AddGlobalAttributesGroupButton, NewOrderUpdateGroup } from "../constants";
import { AddGlobalsButton } from "./addGlobalAttributes.styles";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { RootState } from "stores/application.store";
import { AddItemText } from "constants/text";
import { useNavigate, useParams } from "react-router-dom";

const stepKeys = {
	stepOneUpdate: ["doorStyle"],
	stepOneAdd: ["productLine", "doorStyle"],
	stepTwo: ["shape", "species", "finish"],
	stepThree: ["construction", "packaging"],
	stepFour: ["accountNumber", "billToNumber"]
} as const;

interface AddGlobalAttributesProps {
	isUpdate?: boolean;
	showAddItem?: boolean;
	isAddOn?: boolean;
	handleConfigurationUpdate?: (configuration: ImportConfigurationViewModel) => void;
	setConfigurationToUpdate?: () => void;
}

const AddGlobalAttributes = ({
	isUpdate,
	showAddItem,
	isAddOn = false,
	handleConfigurationUpdate,
	setConfigurationToUpdate
}: AddGlobalAttributesProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [step, setStep] = useState(0);
	const { resetField, trigger, watch, reset } = useFormContext<GlobalAttributeFields>();
	const updatedGlobalAttributes = useSelector((state: RootState) => state.newOrder.updatedGlobalAttributes);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();

	const steps = [
		{
			title: "1 Step",
			description: "1 Step",
			content: <StepOne isUpdate={isUpdate} />
		},
		{
			title: "2 Step",
			description: "2 Step",
			content: <StepTwo isUpdate={isUpdate} />
		},
		{
			title: "3 Step",
			description: "3 Step",
			content: <StepThree isUpdate={isUpdate} />
		},
		{
			title: "4 Step",
			description: "4 Step",
			content: <StepFour isUpdate={isUpdate} />
		}
	];

	const maxSteps = isUpdate ? steps.length - 1 : steps.length; // Update only has 3 steps
	const currentStep = steps[step];

	const selectedProductLine = watch("productLine");
	const selectedDoorStyle = watch("doorStyle");
	const selectedFinish = watch("finish");

	useEffect(() => {
		if (selectedProductLine) {
			const fields = ["doorStyle", ...stepKeys.stepTwo, ...stepKeys.stepThree, ...stepKeys.stepFour] as const;

			fields.forEach((field) => resetField(field, { defaultValue: null as any }));
		}
	}, [dispatch, resetField, selectedProductLine]);

	useEffect(() => {
		if (selectedDoorStyle) {
			const fields = [...stepKeys.stepTwo, ...stepKeys.stepThree] as const;
			fields.forEach((field) => resetField(field, { defaultValue: null as any }));
		}
	}, [resetField, selectedDoorStyle]);

	useEffect(() => {
		if (selectedFinish) {
			const fields = [...stepKeys.stepThree] as const;
			fields.forEach((field) => resetField(field, { defaultValue: null as any }));
		}
	}, [resetField, selectedFinish]);

	const onStepperForward = async (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		let isValid = false;

		if (step === 0) {
			isValid = await trigger(isUpdate ? stepKeys.stepOneUpdate : stepKeys.stepOneAdd);
		} else if (step === 1) {
			isValid = await trigger(stepKeys.stepTwo);
		} else if (step === 2) {
			isValid = await trigger(stepKeys.stepThree);
		}

		if (isValid) {
			setStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const onStepperBack = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleAddAttributes = () => {
		reset();
		setIsOpen(true);
	};

	const closeAndClear = () => {
		reset();
		setStep(0);
		setIsOpen(false);
	};

	const startUpdateAttributes = () => {
		reset();
		if (setConfigurationToUpdate) {
			setConfigurationToUpdate();
		}
		setIsOpen(true);
	};

	const onSave = (values: GlobalAttributeFields) => {
		if (!isUpdate) {
			const newConfiguration: ImportConfigurationViewModel = {
				doorStyle: values.doorStyle?.description ?? null,
				doorStyleCode: values.doorStyle?.code ?? null,
				doorStyleId: values.doorStyle?.id ?? null,
				finish: values.finish?.description ?? null,
				finishCode: values.finish?.code ?? null,
				finishId: values.finish?.id ?? null,
				accountNumber: values.accountNumber ?? null,
				billToNumber: values.billToNumber ?? null,
				species: values.species?.description ?? null,
				speciesCode: values.species?.code ?? null,
				speciesId: values.species?.id ?? null,
				productLine: values.productLine?.description ?? null,
				productLineId: values.productLine?.id ?? null,
				productLineCode: values.productLine?.code ?? null,
				items: [],
				brand: values.productLine?.description ?? null,
				parentBrand: values.productLine?.parentBrand ?? null,
				shape: values.shape?.description ?? null,
				shapeCode: values.shape?.code ?? null,
				construction: values.construction?.description ?? null,
				constructionCode: values.construction?.code ?? null,
				packaging: values.packaging?.description ?? null,
				packagingCode: values.packaging?.code ?? null,
				shapeId: values.shape?.id ?? null,
				constructionId: values.construction?.id ?? null,
				packagingId: values.packaging?.id ?? null
			};
			dispatch(newOrderActions.addGlobalAttributes(newConfiguration));
			if (isAddOn) {
				navigate(`/add-ons/build-order/${params.orderId}`);
			} else if (showAddItem) {
				navigate("/new-order/build-order");
			}
		} else if (handleConfigurationUpdate && updatedGlobalAttributes) {
			handleConfigurationUpdate(updatedGlobalAttributes);
		}
		closeAndClear();
	};

	return (
		<>
			{isUpdate ? (
				<Button
					variant="outlined"
					data-testid="update-global-attributes"
					onClick={startUpdateAttributes}
					startIcon={<EditIcon />}
				>
					{NewOrderUpdateGroup}
				</Button>
			) : (
				<>
					{showAddItem ? (
						<Button
							onClick={handleAddAttributes}
							variant={isAddOn ? "contained" : "outlined"}
							startIcon={
								<AddIcon
									fontSize="small"
									sx={{ marginBottom: "2px" }}
								/>
							}
							sx={{
								display: "flex",
								alignItems: "center",
								height: "fit-content",
								width: isAddOn ? "100%" : "max-content"
							}}
						>
							{AddItemText}
						</Button>
					) : (
						<AddGlobalsButton
							variant="text"
							onClick={handleAddAttributes}
							data-testid="add-global-attribute-button"
						>
							<AddIcon />
							{AddGlobalAttributesGroupButton}
						</AddGlobalsButton>
					)}
				</>
			)}
			<AddGlobalAttributesDialog
				isUpdate={isUpdate ?? false}
				dialogState={isOpen}
				handleDialogClose={closeAndClear}
				handleStepperBack={onStepperBack}
				handleStepperForward={onStepperForward}
				step={step}
				maxSteps={maxSteps}
				onSave={onSave}
			>
				<DialogContent>{currentStep.content}</DialogContent>
			</AddGlobalAttributesDialog>
		</>
	);
};

export default AddGlobalAttributes;
