import styled from "@emotion/styled";
import { allColors } from "styles/themes/common/colors";
import { Accordion, AccordionSummary, Dialog, paperClasses, TextField } from "@mui/material";
import { StyledDialogTitle } from "components/Dialog/dialog.styles";
import { LoadingSkeletonProps } from "../Common/LineItemGrid/LineItemGridStyles";

interface NewOrdersProps {
	isNewOrderPage?: boolean;
	isConfirmation?: boolean;
}

export const DraftNewOrderHeader = styled.h2({
	color: allColors.hex.blue.cwg.cwgBlue900,
	fontSize: "var(--font-size-7)",
	fontWeight: "var(--font-weight-regular)",
	padding: "20px 0 0",
	margin: 0
});

export const ImportCSVTextContainer = styled.h3({
	fontSize: 20,
	margin: 0,
	fontWeight: 400
});

export const OrderOrAddOnText = styled.h3(({ theme }) => ({
	color: theme.project.colors.fontColors.main,
	fontSize: 20,
	margin: 0,
	fontWeight: 400
}));

export const UnsetStyles = styled.p({
	marginBottom: "unset"
});

export const UnsetSecondaryCopy = styled(UnsetStyles)(({ theme }) => ({
	...theme.project.typography.body.body1,
	color: theme.project.colors.fontColors.secondary
}));

export const GridChildTwoContainer = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "4fr 1fr",
	columnGap: "20px",
	padding: "16px",
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr",
		gridTemplateRows: "1fr auto 1fr",
		button: {
			gridArea: "3",
			width: "100%",
			maxHeight: "37px"
		},
		p: {
			marginBottom: "16px"
		}
	}
}));

export const ManualOrderEntryAddOn = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	padding: "16px",
	p: {
		marginBottom: "36px"
	},
	width: "calc(50% - 24px)",
	[theme.breakpoints.down("md")]: {
		width: "calc(100% - 32px)",
		p: {
			marginBottom: "16px"
		}
	}
}));

export const AddOnEnterOrder = styled.div(({ theme }) => ({
	marginTop: "24px",
	display: "flex",
	gap: "24px",
	[theme.breakpoints.down("md")]: {
		flexDirection: "column"
	}
}));

export const SupportedFilesTextSize = styled.p(({ theme }) => ({
	...theme.project.typography.caption,
	color: theme.project.colors.fontColors.secondary,
	fontSize: "14px",
	margin: "7px 0"
}));

export const NewOrdersHeaderGrid = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "3fr 4fr",
	gap: "20px",
	margin: "0 0 16px 0",
	[theme.breakpoints.down("md")]: {
		display: "flex",
		flexDirection: "column"
	}
}));

export const GridChildOneStyles = styled.div<{ isAddOn?: boolean }>(({ theme, isAddOn }) => ({
	backgroundColor: "white",
	padding: "16px",
	color: theme.project.colors.fontColors.main,
	display: "flex",
	flexDirection: "column",
	width: isAddOn ? "calc(50% - 24px)" : "auto",
	[theme.breakpoints.down("md")]: {
		width: isAddOn ? "calc(100% - 32px)" : "auto"
	}
}));

export const GridChildTwoStyles = styled.div(({ theme }) => ({
	backgroundColor: "white",
	color: theme.project.colors.fontColors.secondary,
	height: "max-content"
}));

export const AccountBillToDialogFormContainer = styled.div({
	maxHeight: "600px"
});

export const HeaderTitleStyles = styled.div({
	h2: {
		paddingBottom: "unset"
	}
});

export const NewOrdersDialogWrapper = styled(Dialog)({
	[`.${paperClasses.root}`]: {
		width: "100%"
	}
});

export const AllFieldsText = styled.div({
	paddingLeft: "24px"
});

/// // CSV UPLOAD MODAL STYLES
export const NewOrderModalDisclaimer = styled.div({
	marginTop: "unset",
	marginBottom: "unset"
});

export const UploadErrorHeader = styled.p({
	textTransform: "uppercase",
	fontFamily: "Gibson Regular",
	margin: 0
});

export const UploadCSVDragDropUploadFile = styled.div({
	svg: {
		fontSize: 40,
		marginTop: 36
	}
});

export const UploadErrorContent = styled.div({
	marginTop: 8
});

export const UploadErrorWarning = styled.div({
	marginTop: 6,
	display: "flex",
	alignItems: "center",
	color: "#D2393B"
});

export const UploadErrorWaringText = styled.div({
	marginLeft: 8
});

export const UploadCSVDragDropButton = styled.div({
	marginTop: 0,
	marginBottom: "1rem",
	span: {
		marginLeft: 6
	}
});

export const CSVUploadIsUpdating = styled.div({
	img: {
		margin: "auto",
		display: "block"
	},
	h2: {
		textAlign: "center",
		display: "block"
	}
});

export const UploadModalCSVFileInfoContainer = styled.div({
	color: "var(--text-primary)",
	marginTop: "-8px"
});

export const UploadModalCSVFileInfoContainerButton = styled.div({
	display: "flex",
	justifyContent: "flex-end"
});

interface IsBuildOrderPageProps {
	isBuildOrderPage: boolean | undefined;
	isBillToError?: boolean;
}

export const AccountBillToContentWrapper = styled.div<IsBuildOrderPageProps>(
	({ theme, isBuildOrderPage, isBillToError }) =>
		isBuildOrderPage
			? {
					display: "grid",
					gridTemplateColumns: "auto 1fr 1fr min-content min-content",
					backgroundColor: theme.palette.grey[50],
					padding: 16,
					alignItems: isBillToError ? "flex-start" : "center",
					margin: "0 16px",
					border: "1px solid rgba(0, 0, 0, 0.12)",
					gap: theme.project.layout.gap.large,
					"button:nth-of-type(1)": {
						textWrap: "nowrap",
						alignItems: "center"
					},
					"button:nth-of-type(2)": {
						textWrap: "nowrap",
						gap: 8
					},

					[theme.breakpoints.down("md")]: {
						flexDirection: "column",
						alignItems: "flex-start",
						gridTemplateColumns: "1fr",
						"button:nth-of-type(1)": {
							order: 2
						},
						"button:nth-of-type(2)": {
							order: 1
						}
					}
				}
			: {
					display: "flex",
					flexDirection: "column",
					gap: theme.project.layout.gap.large
				}
);

export const FileContentsContainer = styled.div<IsBuildOrderPageProps>(({ theme, isBuildOrderPage }) => ({
	display: !isBuildOrderPage ? "grid" : "block",
	gridTemplateColumns: "repeat(6, 1fr)",
	gap: "16px",
	div: {
		[theme.breakpoints.up("md")]: {
			"&:nth-of-type(2)": {
				gridColumn: "span 2"
			},
			"&:nth-of-type(n + 3)": {
				gridColumn: "span 1"
			}
		},
		[theme.breakpoints.down("md")]: {
			gridColumn: "1 / -1",
			display: "flex",
			gap: "8px"
		}
	}
}));

export const FileContentsLogo = styled.div<IsBuildOrderPageProps>(({ isBuildOrderPage }) => ({
	gridColumn: "span 2",
	alignItems: "flex-start",
	display: "flex",
	flexDirection: "column",
	height: !isBuildOrderPage ? "50px" : "auto",
	alignSelf: "center",
	img: {
		height: isBuildOrderPage ? "14px" : ""
	}
}));

export const SummaryProductLine = styled.div<NewOrdersProps>(({ theme }) => ({
	paddingTop: "16px"
}));

export const AccountBillToContentTextField = styled(TextField)<{ isError: boolean }>(({ theme, isError }) => ({
	div: {
		fieldset: {
			borderColor: isError ? "var(--red-700) !important" : "var(--gray-400)"
		},
		backgroundColor: theme.project.colors.backgroundColors.main
	}
}));

export const AccountBillToError = styled.div({
	display: "flex",
	alignItems: "center",
	gap: 8,
	margin: "8px 0 0 0",
	height: 20,
	color: "var(--red-700)",
	p: {
		margin: "3px 0 0 0",
		verticalAlign: "middle"
	},
	svg: {
		width: 20,
		height: 20
	}
});

export const StartOverContinueContainer = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	gap: "16px",
	[theme.breakpoints.down("md")]: {
		flexDirection: "column-reverse"
	}
}));

export const BuildNewOrderLineItemsFlexbox = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: 16,
	"& .Mui-expanded": {
		margin: 0
	}
});

export const BuildNewOrderLineItemsContainer = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.allColors.hex.grayscale.gray100
}));

export const BuildNewOrderLineItemsHeaderWrapper = styled(AccordionSummary)<LoadingSkeletonProps>(
	({ isLoading, theme }) => ({
		display: isLoading ? "none" : "flex",
		padding: "8px 16px",
		position: "relative",
		"& .MuiAccordionSummary-content.Mui-expanded": {
			margin: "12px 0"
		},
		"& .MuiAccordionSummary-expandIconWrapper": {
			marginRight: 12,
			color: theme.project.colors.fontColors.main
		},
		[theme.breakpoints.down("md")]: {
			paddingBottom: 4,
			"& .MuiAccordionSummary-expandIconWrapper": {
				bottom: 29,
				right: 12,
				position: "absolute"
			}
		}
	})
);

export const BuildNewOrderLineItemsHeader = styled.div<LoadingSkeletonProps>(({ isLoading, theme }) => ({
	display: isLoading ? "none" : "flex",
	width: "100%",
	alignItems: "center",
	justifyContent: "space-between",
	margin: 0,
	div: {
		"&:first-of-type": {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			fontWeight: 500,
			fontSize: "var(--font-size-6)"
		},
		"&:nth-child(2)": {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "0.75rem", // 12px
			fontStyle: "normal",
			fontWeight: 400,
			letterSpacing: "1px",
			lineHeight: "266%",
			textTransform: "uppercase"
		}
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		alignItems: "flex-start",
		div: {
			"&:first-child": {
				display: "block",
				marginBottom: 16
			},
			"&:nth-child(2)": {
				height: 48,
				display: "flex",
				alignItems: "center"
			}
		}
	}
}));
export const NewOrderFlowButtons = styled.div<LoadingSkeletonProps>(({ isLoading, theme }) => ({
	marginTop: 24,
	display: isLoading ? "none" : "flex",
	gap: 16,
	justifyContent: "flex-end",
	button: {
		textTransform: "uppercase",
		"&:nth-of-type(2)": {
			minWidth: 343
		}
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column-reverse",
		gap: 10,
		"button:nth-of-type(2)": {
			minWidth: "auto"
		}
	}
}));

export const UploadModalCSVFileAttachmentViewBytes = styled.span({
	marginLeft: 10
});

export const AccountBillToDialogTitle = styled(StyledDialogTitle)({
	padding: "24px 24px 0 24px"
});

// New Orders
export const NewOrdersContent = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.allColors.hex.grayscale.gray100,
	"& > div:nth-of-type(2)": {
		display: "flex",
		flexDirection: "column",
		gap: 24
	}
}));

export const NewOrdersSubheaderWrapper = styled.div<LoadingSkeletonProps>(({ theme, isLoading }) => ({
	div: {
		":first-of-type": {
			display: isLoading ? "none" : "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			padding: "16px 0",
			h1: {
				color: theme.project.colors.primaryColors.main,
				fontSize: "var(--font-size-7)",
				margin: 0,
				fontWeight: 400
			},
			div: {
				display: "flex",
				gap: 16,
				padding: 0,
				div: {
					width: "341px"
				}
			},
			"& .MuiAutocomplete-endAdornment": {
				flexDirection: "row",
				justifyContent: "flex-end",
				gap: "0"
			},
			[theme.breakpoints.down("md")]: {
				div: {
					width: "100%",
					flexDirection: "column",
					alignItems: "flex-start",
					div: {
						width: "100%"
					}
				},
				flexDirection: "column",
				gap: 16,
				alignItems: "flex-start"
			}
		}
	}
}));

export const SaveDraftErrorBanner = styled.div(({ theme }) => ({
	padding: "16px",
	display: "flex",
	flexDirection: "row",
	backgroundColor: "#FDEDED",
	color: theme.project.colors.semanticColors.error,
	marginBottom: "16px",
	svg: {
		margin: "4px 12px 7px 0",
		width: "22px",
		height: "22px"
	},
	h6: {
		...theme.project.typography.body.body1,
		fontWeight: 500,
		margin: 0,
		paddingBottom: 4
	}
}));

export const SubmitTandemSupportMessage = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	div: {
		":first-of-type": {
			...theme.project.typography.body.body2,
			display: "flex",
			flexDirection: "row",
			"& > a:first-of-type": {
				...theme.project.typography.subtitle.subtitle2,
				color: theme.project.colors.semanticColors.error
			},
			"& > a:last-of-type": {
				color: theme.project.colors.semanticColors.error,
				svg: {
					margin: 0,
					width: 16,
					height: 16
				}
			}
		},
		":last-of-type": {
			...theme.project.typography.caption,
			marginTop: 12
		}
	}
}));

export const SubmitDraft400Message = styled.div(({ theme }) => ({
	...theme.project.typography.body.body2,
	display: "flex",
	flexDirection: "column",
	ul: {
		marginTop: 0
	},
	"& > div:last-of-type": {
		...theme.project.typography.caption,
		marginTop: 12
	}
}));

export const NewOrdersButtonWrapper = styled.div(({ theme }) => ({
	display: "flex",
	gap: 16,
	justifyContent: "flex-end",
	"button:nth-of-type(1)": {
		textTransform: "uppercase"
	},
	"button:nth-of-type(2)": {
		width: 345
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		"button:nth-of-type(1)": {
			order: 2
		},
		"button:nth-of-type(2)": {
			width: "100%"
		}
	}
}));

export const CardWrapper = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	border: theme.project.borders.presets.outline,
	display: "flex",
	flexDirection: "column",
	gap: 16,
	padding: 16
}));

export const ShippingDetailsCard = styled(CardWrapper)(({ theme }) => ({
	"& > div:nth-of-type(1)": {
		...theme.project.typography.subtitle.subtitle1,
		color: theme.project.colors.fontColors.secondary
	},
	"& > div:nth-of-type(2)": {
		...theme.project.typography.caption,
		color: theme.project.colors.fontColors.secondary
	},
	"& > div:nth-of-type(3)": {
		[theme.breakpoints.up("md")]: {
			width: "50%"
		}
	},
	"& > div:last-of-type": {
		display: "flex",
		gap: 16,
		[theme.breakpoints.down("md")]: {
			flexDirection: "column"
		}
	}
}));

export const NewOrdersLayoutContainer = styled.div(({ theme }) => ({
	margin: "0 0 20px 248px",
	[theme.breakpoints.down("lg")]: {
		margin: "unset"
	}
}));

export const NewOrdersHeaderContainer = styled.div<NewOrdersProps>(({ theme, isConfirmation }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	display: "flex",
	flexDirection: "column",
	gap: 16,
	margin: 0,
	padding: isConfirmation ? 24 : 20,
	position: "fixed",
	top: 0,
	width: "calc(100% - 288px)", // 100% - (sidebar-width + header-paddings)
	zIndex: "99",
	h1: {
		color: theme.project.colors.fontColors.main,
		fontSize: 34,
		margin: 0
	},
	[theme.breakpoints.down("lg")]: {
		top: 56, // height of top nav menu
		width: "calc(100% - 40px)" // 100v%- header-paddings (under lg mobile view, top nav is present & does not effect width of header)
	},
	[theme.breakpoints.down("md")]: {
		position: "unset",
		top: "unset",
		width: "unset"
	}
}));

export const AddOnsHeaderContainer = styled(NewOrdersHeaderContainer)(({ theme }) => ({
	position: "relative",
	top: 0,
	width: "calc(100% - 40px)",
	[theme.breakpoints.down("lg")]: {
		top: 0
	}
}));

export const AddOnsLandingPageEnterOrderContainer = styled.div({
	padding: "0 20px"
});

export const NewOrdersHeaderHead = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	h5: {
		margin: 0
	}
});

export const NewOrdersSummaryDetails = styled.div<NewOrdersProps>(({ isNewOrderPage, isConfirmation, theme }) => ({
	alignItems: "flex-start",
	backgroundColor: isNewOrderPage || isConfirmation ? "none" : theme.palette.grey[100],
	padding: isNewOrderPage || isConfirmation ? 0 : 16,
	marginTop: isNewOrderPage || isConfirmation ? 8 : 0,
	gap: 16,
	display: "grid",
	textTransform: "none",
	gridTemplateColumns: "repeat(5, 1fr)",
	"& > div": {
		padding: "4px 0px",
		"& > :nth-of-type(1)": {
			...theme.project.typography.subtitle.subtitle2,
			color: theme.project.colors.fontColors.main
		},
		"& > :nth-of-type(2)": {
			...theme.project.typography.body.body2,
			color: theme.project.colors.fontColors.secondary
		},
		"& > :nth-child(n+3)": {
			...theme.project.typography.body.body2,
			color: theme.project.colors.fontColors.secondary
		}
	},
	"& > div:nth-of-type(1)": {
		gridColumn: isNewOrderPage || isConfirmation ? "0" : "1 / span 2"
	},
	[theme.breakpoints.down("md")]: {
		"& > div:nth-of-type(n)": {
			gridColumn: "1 /span 5"
		}
	}
}));

export const NewOrdersContentContainer = styled.div<{ marginTopOffset?: number }>(({ marginTopOffset, theme }) => ({
	backgroundColor: theme.project.colors.allColors.hex.grayscale.gray100,
	marginTop: marginTopOffset ?? 0,
	padding: "0 20px",
	display: "flex",
	flexDirection: "column",
	gap: 16,
	[theme.breakpoints.down("md")]: {
		marginTop: 0
	}
}));

export const NewOrdersHeaderRow = styled.div(({ theme }) => ({
	alignItems: "center",
	display: "flex",
	justifyContent: "space-between",
	[theme.breakpoints.down("md")]: {
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexDirection: "column",
		gap: 16
	}
}));

export const NewOrderDetailsWrapper = styled.div<NewOrdersProps>(({ isConfirmation, theme }) => ({
	border: isConfirmation ? "1px solid" : "none",
	borderColor: isConfirmation ? theme.project.colors.allColors.hex.grayscale.gray300 : "none",
	padding: isConfirmation ? 16 : 0
}));

export const StaticComponentBackground = styled.div(({ theme }) => ({
	padding: 16,
	marginBottom: 16,
	backgroundColor: theme.project.colors.backgroundColors.main
}));

export const NewOrderConfirmationOrderListHeader = styled.div(({ theme }) => ({
	padding: 16,
	backgroundColor: theme.project.colors.backgroundColors.main,
	...theme.project.typography.subtitle.subtitle1,
	color: theme.project.colors.fontColors.secondary,
	fontSize: "0.75rem",
	"& > div:nth-of-type(1)": {
		marginBottom: 8
	}
}));

export const StaticComponentTitle = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle1,
	color: theme.project.colors.fontColors.secondary,
	marginBottom: 8,
	span: {
		float: "right"
	}
}));

export const StaticComponentGridDividerWrapper = styled.div(({ theme }) => ({
	marginTop: 16,
	marginBottom: 16
}));

export const StaticComponentGrid = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(2, 1fr)",
	gap: 16,
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr"
	}
}));

export const Label = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle2,
	color: theme.project.colors.fontColors.secondary
}));

export const InfoText = styled.div(({ theme }) => ({
	...theme.project.typography.body.body2,
	color: theme.project.colors.fontColors.main
}));

export const OriginalOrderDetailsAccordion = styled(Accordion)(({ theme }) => ({
	"& .MuiAccordionSummary-content": {
		...theme.project.typography.overline,
		margin: "9px 0"
	},
	boxShadow: "none",
	position: "initial",
	border: "1px solid " + theme.palette.grey[300],
	marginTop: "0px !important",
	"& .MuiAccordionDetails-root": {
		padding: 0,
		div: {
			margin: 0,
			paddingTop: 0
		}
	},
	"& .Mui-expanded": {
		margin: "0px !important"
	}
}));
