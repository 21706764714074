import { useState, useEffect } from "react";
import { getFileNameBasedOnCurrentDateTime } from "../utils/file";
import getNewOrdersModuleAssetUrl from "mocks/getNewOrdersModuleAssetUrl";
import { ViewPriceEstimatesConfigurationModel } from "components/NewOrders/ViewPriceEstimates/view-price-estimates-model.ts";
import { NewOrderSummaryDetailModel } from "components/NewOrders/NewOrdersHeader/new-order-summary-details-model.ts";
import { CreatePendingOrderRequest } from "data/api/v1";

const useNewOrdersPDFGenerator = (
	configurations: ViewPriceEstimatesConfigurationModel[] | undefined,
	orderSummary: NewOrderSummaryDetailModel,
	headerText: string,
	subtotal: number,
	orderDetails?: CreatePendingOrderRequest | undefined,
	isReview?: boolean,
	isConfirmation?: boolean
) => {
	const [pdfData, setPdfData] = useState<PDFData>({ blob: null, loading: false, error: null });

	const generateNewOrdersPDF = () => {
		const worker = getNewOrdersModuleAssetUrl();

		worker.postMessage({
			configurations,
			orderSummary,
			headerText,
			subtotal,
			orderDetails,
			isReview,
			isConfirmation
		});

		worker.onmessage = (e) => {
			if (e.data.blob) {
				setPdfData({ blob: e.data.blob, loading: false, error: null });
			} else if (e.data.error) {
				setPdfData((prevData) => ({ ...prevData, loading: false, error: e.data.error }));
			}
		};

		worker.onerror = (error) => {
			setPdfData((prevData) => ({
				...prevData,
				loading: false,
				error: error instanceof Error ? error : new Error("An unknown error occurred")
			}));
		};

		setPdfData((prevData) => ({ ...prevData, loading: true }));
	};

	const jobName = orderDetails?.jobName ? `_${orderDetails.jobName}` : "";
	const poNumber = orderDetails?.poNumber ? `_PO${orderDetails.poNumber}` : "";
	const filePrefix = `NewOrder${jobName}${poNumber}`;

	useEffect(() => {
		if (pdfData.blob) {
			const url = window.URL.createObjectURL(pdfData.blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${getFileNameBasedOnCurrentDateTime(filePrefix)}.pdf`);
			document.body.appendChild(link);
			link.click();
			setPdfData({ blob: null, loading: false, error: null });
		}
	}, [pdfData.blob, jobName, poNumber, filePrefix]);

	return { pdfData, generateNewOrdersPDF };
};

interface PDFData {
	blob: Blob | null;
	loading: boolean;
	error: Error | null;
}

export default useNewOrdersPDFGenerator;
