import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "data/api/v1/endpoints.ts";

import { APIErrorResponse, baseQueryWithRedirect, UntransformedResponse } from "features/api/utils/apiUtils.ts";
import { ImportOrderViewModel } from "data/api/v1/model/import-order-view-model.ts";
import { OrderValidationViewModel } from "data/api/v1/model/order-validation-view-model.ts";
import { CreatePendingOrderRequest, ProductViewModel } from "data/api/v1";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model.ts";

export interface BillToShipToBody {
	accountId: string;
	billToId: string;
}

export const newOrderApiSlice = createApi({
	reducerPath: "newOrderAPI",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		uploadCSV: builder.mutation({
			query: (csvUpload) => ({
				url: endpoints.newOrder.csvUpload(),
				method: "POST",
				formData: true,
				body: csvUpload
			}),
			transformResponse: (response: UntransformedResponse<ImportOrderViewModel>) => response,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		validateOrderRequest: builder.mutation({
			query: (orderRequest) => ({
				url: endpoints.newOrder.validateOrderRequest(),
				method: "POST",
				dataType: "json",
				headers: {
					"Content-type": "application/json; charset=UTF-8"
				},
				body: orderRequest
			}),
			transformResponse: (response: UntransformedResponse<OrderValidationViewModel>) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		createOrder: builder.mutation({
			query: (order: CreatePendingOrderRequest | undefined) => ({
				url: endpoints.newOrder.postNewOrder(),
				method: "POST",
				dataType: "json",
				body: order
			}),
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		newOrderShipToAddresses: builder.mutation({
			query: (accountIds: BillToShipToBody[] | undefined) => ({
				url: endpoints.newOrder.getBillToShipTosNewOrders(),
				method: "POST",
				dataType: "json",
				body: accountIds
			}),
			transformResponse: (response: UntransformedResponse<ShipToViewModel[]>) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		getLineItems: builder.query<ProductViewModel[], string>({
			query: (productLineCode: string) => endpoints.newOrder.getProductsByProductLineCode(productLineCode),
			transformResponse: (response: { data: ProductViewModel[] }) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		})
	})
});

export const {
	useCreateOrderMutation,
	useUploadCSVMutation,
	useValidateOrderRequestMutation,
	useNewOrderShipToAddressesMutation,
	useGetLineItemsQuery
} = newOrderApiSlice;
