import {
	AddLineItemContainer,
	AddLineItemButtonWrapper,
	AddLineItemInputFormControl,
	AddLineItemStandardContainer,
	AddLineItemStandardDimensions,
	AddItemButton
} from "./AddLineItemStyles.ts";
import { Button } from "@mui/material";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import AutoComplete from "../../Common/Autocomplete/Autocomplete.tsx";
import { useGetLineItemsQuery } from "features/api/newOrderApi.ts";
import AddIcon from "@mui/icons-material/Add";
import { ImportConfigurationViewModel } from "data/api/v1";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import {
	AddLineDepthLabel,
	AddLineHeightLabel,
	AddLineItemHeader,
	AddLineItemProductPlaceholder,
	AddLineItemQuantityPlaceholder,
	AddLineItemRequired,
	AddLineWidthLabel,
	AddLineItemStandardDimensionsHeader,
	AddLineCancelButton,
	AddLineAddAnotherItemButton,
	AddLineSaveButton,
	AddLineAddAnotherTextOne,
	AddLineAddModificationButton,
	AddLineItemInvalidProductError,
	AddLineEnterText
} from "./constants.ts";
import { ImportItemViewModel } from "data/api/v1/model/import-item-view-model.ts";

interface AddNewLineItemModalProps {
	addItemVisibility(index?: number): void;
	configuration: ImportConfigurationViewModel;
	index: number;
	handleLineItem: (configIndex: number, newLineItem: ImportItemViewModel) => void;
}

const AddLineItem = ({ addItemVisibility, configuration, index, handleLineItem }: AddNewLineItemModalProps) => {
	const [quantityValue, setQuantityValue] = useState(1);
	const [saveButtonClick, setSaveButtonClick] = useState(false);
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const { control, handleSubmit, trigger } = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange"
	});

	const param = configuration.productLineCode ?? "";
	const { data: newLineItems, isLoading } = useGetLineItemsQuery(param, { skip: !param });

	useEffect(() => {
		const handleQuickKeys = (event: any) => {
			if (
				event.key === "Enter" &&
				document.activeElement &&
				document.activeElement.getAttribute("data-testid") === "new-order-draft-table-add-another-button"
			) {
				trigger().then((valid) => {
					valid && handleSaveAction();
				});
			}
			// had to duplicate trigger to get shift key to work
			if (event.shiftKey) {
				event.preventDefault();
				trigger().then((valid) => {
					valid && handleSaveAction();
				});
			}
		};
		document.addEventListener("keydown", handleQuickKeys);

		return () => {
			document.removeEventListener("keydown", handleQuickKeys);
		};
	});

	const handleDraftSearch = (_event: SyntheticEvent, values: string | null) => {
		setSearchValue(values ?? null);
	};

	const quantityValues = Array.from({ length: 99 }, (_, i) => i + 1);
	const quantity = quantityValues.join().split(",");

	const newLineItemMap = useMemo(() => {
		if (!isLoading) {
			return newLineItems?.map((item) => {
				return `${item.sku} - ${item.description}`;
			});
		}
	}, [isLoading, newLineItems]);
	const handleMouseDownCapture = (e: SyntheticEvent) => {
		e.stopPropagation();
	};

	const handleQuantityChange = (_event: SyntheticEvent, value: number) => {
		setQuantityValue(value);
	};

	const searchSku = searchValue?.split("-")[0];
	const searchDescription = searchValue?.split(" - ")[1];

	const saveButtonClicked = () => {
		setSaveButtonClick(true);
	};

	const handleSaveAction = () => {
		if (searchValue !== null) {
			const newLineItem: ImportItemViewModel = {
				description: searchDescription ?? null,
				lineNumber: "1",
				manufacturerCode: searchSku ?? null,
				modifications: [],
				quantity: quantityValue,
				userCode: searchSku ?? null
			};

			handleLineItem(index, newLineItem);
			setSearchValue(null);
			setQuantityValue(1);

			if (saveButtonClick) {
				setSearchValue(null);
				addItemVisibility();
			}
		}
	};

	const foundSearch = newLineItems?.find((item) => `${item.sku} - ${item.description}`.includes(searchValue!));

	return (
		<form
			onSubmit={(event) => {
				event.stopPropagation();
				handleSubmit(handleSaveAction)(event);
			}}
			noValidate
		>
			<AddLineItemContainer>
				<div data-testId="new-order-draft-table-line-header">{AddLineItemHeader}</div>

				<div data-testId="new-order-draft-table-line-required">{AddLineItemRequired}</div>

				<AddLineItemInputFormControl>
					<Controller
						name="productSelect"
						control={control}
						render={({ field: { onChange, value = null }, fieldState: { error } }) => (
							<div>
								<AutoComplete
									dataTestId="new-order-save-draft-table-search"
									options={newLineItemMap ?? []}
									value={searchValue ?? ""}
									onChange={(event, values) => {
										handleDraftSearch(event, values ?? null);
										onChange(values);
									}}
									onMouseDownCapture={handleMouseDownCapture}
									defaultValue={quantityValue}
									isError={Boolean(error)}
									errorText={error?.message}
									label={AddLineItemProductPlaceholder}
									required
									disableClearable
									noOptionsText={AddLineItemInvalidProductError}
								/>
							</div>
						)}
					/>

					<AutoComplete
						options={quantity}
						value={quantityValue}
						onChange={handleQuantityChange}
						onMouseDownCapture={handleMouseDownCapture}
						defaultValue={quantityValue}
						isError={false}
						errorText=""
						label={AddLineItemQuantityPlaceholder}
						dataTestId="new-order-draft-table-line-item-quantity"
						required
						disableClearable
					/>
				</AddLineItemInputFormControl>

				{foundSearch && searchValue && (
					<AddLineItemStandardContainer>
						<div data-testId="new-order-draft-standard-dimensions-header">
							{AddLineItemStandardDimensionsHeader}
						</div>
						<AddLineItemStandardDimensions>
							<div data-testId="new-order-draft-standard-dimensions-depth">
								<span>{AddLineDepthLabel}</span>
								<span>{foundSearch.standardDepth}</span>
							</div>
							<div data-testId="new-order-draft-standard-dimensions-height">
								<span>{AddLineHeightLabel}</span>
								<span>{foundSearch.standardHeight}</span>
							</div>
							<div data-testId="new-order-draft-standard-dimensions-width">
								<span>{AddLineWidthLabel}</span>
								<span>{foundSearch.standardWidth}</span>
							</div>
						</AddLineItemStandardDimensions>
					</AddLineItemStandardContainer>
				)}

				{foundSearch?.allowModifications && (
					<div>
						<AddItemButton
							variant="text"
							data-testid="new-order-draft-add-modification-button"
						>
							<AddIcon />
							{AddLineAddModificationButton}
						</AddItemButton>
					</div>
				)}

				<AddLineItemButtonWrapper>
					<div>
						<Button
							data-testId="new-order-draft-table-cancel-button"
							variant="text"
							onClick={() => addItemVisibility()}
						>
							{AddLineCancelButton}
						</Button>
					</div>

					<div>
						<Button
							data-testId="new-order-draft-table-save-button"
							variant="outlined"
							type="submit"
							onClick={saveButtonClicked}
						>
							{AddLineSaveButton}
						</Button>
						<div>{AddLineEnterText}</div>
					</div>

					<div>
						<Button
							data-testId="new-order-draft-table-add-another-button"
							variant="contained"
							type="submit"
						>
							{AddLineAddAnotherItemButton}
						</Button>
						<div>{AddLineAddAnotherTextOne}</div>
					</div>
				</AddLineItemButtonWrapper>
			</AddLineItemContainer>
		</form>
	);
};

export default AddLineItem;
