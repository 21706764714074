import styles from "../order-status-page-styles.module.css";
import { ReturnToOriginalOrderText, ReplacementResubmitDescription, ReplacementResubmitHeader } from "constants/text";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetByIdQuery } from "features/api/orderApi.ts";
import { ErrorButtonWrapper } from "../ErrorModal.styles";

const ReplacementResubmit = () => {
	const params = useParams();
	const { data } = useGetByIdQuery(params?.orderId || "");
	const navigateTo = useNavigate();

	const returnToOrderDetail = () => {
		navigateTo(`/details/${data?.orderId}`);
	};

	return (
		<div
			className={styles.errorContainer}
			data-testid="replacement-resubmit-error-container"
		>
			<div className={styles.errorContent}>
				<img
					src="/assets/tandem_logos/tandem.png"
					alt="Tandem Logo"
					className={styles.tandemLogo}
					data-testid="replacement-resubmit-tandem-logo"
				/>
				<img
					src="/assets/replacments409.png"
					alt="replacement-resubmit-logo"
					className={styles.noServiceSVG}
					data-testid="replacement-resubmit-logo"
				/>

				<div>
					<h1 data-testid="replacement-resubmit-header">{ReplacementResubmitHeader}</h1>
					<p
						data-testid="replacement-resubmit-description"
						className={styles.errorText}
					>
						{ReplacementResubmitDescription}
					</p>
				</div>

				<ErrorButtonWrapper
					variant="contained"
					onClick={returnToOrderDetail}
					data-testid="replacement-resubmit-original-order-button"
				>
					{ReturnToOriginalOrderText}
				</ErrorButtonWrapper>
			</div>
		</div>
	);
};

export default ReplacementResubmit;
