import styles from "pages/replacements-page-styles.module.css";
import { Button, Grid } from "@mui/material";
import {
	ExportConfirmationText,
	ReturnToOriginalOrderText,
	ReplacementConfirmationHeader,
	ReplacementConfirmationSubheader
} from "constants/text";
import { useNavigate } from "react-router-dom";
import { OrderDetailViewModel } from "../../data/api/v1";
import ExportButtons from "components/ExportFile/ExportButtons";
import { ThanksHeader } from "./ReplacementConfirmationThanks.styles";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useMemo, useRef } from "react";
import { useGetUserDetailsQuery } from "features/api/userApi.ts";
import ExportSnackbar from "components/ExportFile/ExportSnackbar";
import useReplacementConfirmationPDFGenerator from "hooks/useReplacementConfirmationPDFGenerator";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import useSnackbarEffect from "hooks/useSnackbarEffect";

export interface ReplacementConfirmationThanksProps {
	orderDetail: OrderDetailViewModel | null;
	accounts: CustomerAccountViewModel | undefined;
}

const ReplacementConfirmationThanks = ({ orderDetail, accounts }: ReplacementConfirmationThanksProps) => {
	const cartItems = useSelector((state: RootState) => state.cart.replacements);
	const replacementOrder = useSelector((state: RootState) => state.submittingReplacementOrder.order);
	const { data: userDetails } = useGetUserDetailsQuery();
	const userAccountInformation: string = useMemo(() => {
		const accountMatch = userDetails?.user?.accounts?.find(
			(account) => account.number === orderDetail?.accountNumber
		);
		if (accountMatch) {
			return `${accountMatch.number}` + (accountMatch.description ? ` - ${accountMatch.description}` : "");
		} else {
			return "";
		}
	}, [userDetails, orderDetail]);
	const todayDate = useRef(new Date());

	const navigateTo = useNavigate();
	const returnToOrderDetail = () => {
		navigateTo(`/details/${orderDetail?.orderId}`);
	};

	const { pdfData, generateReplacementConfirmationPDF } = useReplacementConfirmationPDFGenerator({
		orderDetail: orderDetail,
		cartItems: cartItems,
		replacementOrder: replacementOrder,
		userAccountInformation: userAccountInformation,
		todayDate: todayDate,
		accounts: accounts
	});

	const { snackbarOpen } = useSnackbarEffect(pdfData.loading);

	return (
		<div className={styles.replacementThanks}>
			<Grid
				xs={12}
				className={styles.replacementThanksContent}
			>
				<ThanksHeader>
					<h5 data-testid="replacement-confirmation-thank-you-header">{ReplacementConfirmationHeader}</h5>
					<ExportButtons
						largePDF={false}
						buttonHeader={ExportConfirmationText}
						generatePDF={generateReplacementConfirmationPDF}
						isOnlyPDF
					/>
					<ExportSnackbar
						open={snackbarOpen}
						fileLoading={pdfData.loading}
						largePDF={false}
					/>
				</ThanksHeader>
				<p
					data-testid="replacement-confirmation-thank-you-subheader"
					className="body1"
				>
					{ReplacementConfirmationSubheader}
				</p>
			</Grid>

			<Button
				variant="contained"
				onClick={returnToOrderDetail}
				data-testid="replacement-confirmation-details-button"
				data-id="replacement-confirmation-details-button"
			>
				{ReturnToOriginalOrderText}
			</Button>
		</div>
	);
};

export default ReplacementConfirmationThanks;
