import { createSlice } from "@reduxjs/toolkit";
import { read } from "hooks/useSession.ts";
import { LineItemModificationViewModel, MeasurementsViewModel } from "data/api/v1";

export interface ReplacementFile {
	fileUrl: string;
	size: number;
	name: string;
	id: string;
	originalLineItemId: string;
}

export interface Part {
	partSku: string;
	partQuantity: number;
	description: string;
	maxQuantity: number;
	id: string;
	partReplacementReason: string | null;
	partReplacementProblem: string | null;
	partDefectCode: string;
	doorStyle: string | null;
	finish: string | null;
	lineItemId: string | null;
	partFileUploads: Array<ReplacementFile>;
}

export interface ReplacementCartItem {
	id: string;
	lineItemNumber: number;
	sku: string;
	description: string;
	cabinetDefectCode: string;
	itemNumber: string;
	construction: string | null;
	hinge: string | null;
	lineItemId: string | null;
	measurements?: MeasurementsViewModel | null;
	doorStyle: string | null;
	finish: string | null;
	modifications: Array<LineItemModificationViewModel> | null;
	quantityOrdered: number;
	wholeCabinetQuantity: number;
	replaceableParts: Part[];
	cabinetReplacementReason: string | null;
	cabinetReplacementProblem: string | null;
	cabinetFileUploads: Array<ReplacementFile>;
}

const persistedCart = read("_cart");

export const initialState = persistedCart || {
	replacements: [] as Array<ReplacementCartItem>,
	draft: null as unknown | ReplacementCartItem
};

const sortReplacements = (replacements: any) => {
	return [...replacements].sort((a, b) => a.lineItemNumber - b.lineItemNumber);
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		updateItem: (state, action) => {
			const newReplacements = state.replacements.filter((item: { id: string }) => item.id !== action.payload.id);
			newReplacements.push(action.payload);
			state.replacements = sortReplacements(newReplacements);
		},
		startEdit: (state, action) => {
			state.draft = state.replacements.find((item: { id: string }) => item.id === action.payload) || null;
		},
		updateDraft: (state, action) => {
			state.draft = action.payload;
		},
		cancelEdit: (state) => {
			state.draft = null;
		},
		confirmEdit: (state) => {
			const newReplacements = state.replacements.filter((item: { id: string }) => item.id !== state.draft.id);
			newReplacements.push(state.draft);
			state.replacements = sortReplacements(newReplacements);
			state.draft = null;
		},
		resetCart: (state) => {
			state.replacements = [];
		},
		removeFromCart: (state, action) => {
			const { partId, sku } = action.payload;

			// Parts
			if (partId) {
				state.replacements = state.replacements
					.map((item: any) => {
						if (item.sku === sku) {
							// Count the initial number of partQuantity values that are zero
							const quantityDifferentThanZero = item.replaceableParts.filter(
								(part: { partQuantity: number }) => part.partQuantity !== 0
							).length;
							// Condition to zero out partQuantity
							const shouldZeroOut = !(item.wholeCabinetQuantity === 0 && quantityDifferentThanZero === 1);

							if (shouldZeroOut) {
								// Update replaceableParts by potentially setting partQuantity to 0 for the matched part
								const updatedReplaceableParts = item.replaceableParts.map((part: { id: string }) => {
									if (part.id === partId) {
										return { ...part, partQuantity: 0 };
									}
									return part;
								});
								// Return the item with the updated replaceableParts
								return {
									...item,
									replaceableParts: updatedReplaceableParts
								};
							} else {
								// If shouldZeroOut condition is not met, mark the item for removal
								return null;
							}
						} else {
							// For items that don't match, return them unchanged
							return item;
						}
					})
					.filter(Boolean); // This filter removes items where the shouldZeroOut condition is not met or all partQuantity values are 0
			} else {
				state.replacements = state.replacements
					.map((item: any) => {
						if (item.sku === sku) {
							const partsSelected =
								item.replaceableParts.filter(
									(part: { partQuantity: number }) => part.partQuantity !== 0
								).length > 0;

							if (partsSelected) {
								return {
									...item,
									wholeCabinetQuantity: 0
								};
							} else {
								return null;
							}
						} else {
							return item;
						}
					})
					.filter(Boolean);
			}
		}
	}
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
